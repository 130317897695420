import React, { useContext } from "react";
import Home from "./Home/Home";
import Chat from "./Chat";
import Database from "./DataBase/Database";
import { Context } from "../../context/setting";
import Setting from "./Setting";
import { createTheme, ThemeProvider } from "@mui/material";
import "./claims.css";

const Claims = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    switch (contextStore?.claimsNew?.selectedHome) {
        case "Home":
            return CliamsTheme(<Home />);
        case "Chat":
            return CliamsTheme(<Chat />);
        case "DataBase":
            return CliamsTheme(<Database />);
        case "Setting":
            return CliamsTheme(<Setting />);

        default:
            return CliamsTheme(<Home />);
    }
};

const CliamsTheme = (children: React.ReactElement) => {
    const theme = createTheme({
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        verticalAlign: "baseline",
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: "none",
                        height: "2rem",
                        borderRadius: "6px",
                        "&:hover": {
                            color: "#007BFF",
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        background: "#fff",
                        "&:hover": {
                            background: "#fff",
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: "none",
                    },
                },
            },
        },
    });
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Claims;
