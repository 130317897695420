import { Paper, Stack, Typography, Button, Box, OutlinedInput, IconButton } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../../../context/setting";
import ClaimSocket from "../../utils/ClaimSocket";
import ProfileAvatar from "../../../../components/Common/svgLogs/ProfileAvatar";
import Typing from "./Typing";
import LogoXIcon from "../../../../components/Common/svgLogs/LogoXIcon";
import SendWhiteIcon from "../../../../components/Common/svgLogs/SendWhiteIcon";




const Chat = ({ setFilterScreeen }: { setFilterScreeen: any }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [pdfName, setPdfName] = useState<string>("");
    const scrollRef = useRef<any>();
    const [msg, socketHooks, msgRef] = ClaimSocket();
    const [msgList, setMsgList] = useState<any>([
        {
            msg: (
                <Stack sx={{ gap: 1 }}>
                    <Typography
                        sx={{
                            color: "#292D32",
                            fontSize: "1rem",
                        }}>
                        Welcome to SoftsensorX! Your go-to for seamless assistance
                    </Typography>
                    <Stack>
                        <Typography sx={{ fontSize: "0.8rem", color: "#6B7582", mt: 0.7 }}>Try Asking</Typography>
                        {[
                            "What are the main topics and key points covered in the document discussing financial analysis concepts?",
                            "Can you provide an overview of the document's content, highlighting the main topics and key points related to financial analysis concepts?",
                            "What specific financial analysis concepts are explored in the document and what are the main takeaways from these discussions?",
                        ]?.map((item) => (
                            <Typography
                                sx={{
                                    fontSize: "0.9rem",
                                    px: 2,
                                    py: 0.4,
                                    background: "rgba(217, 217, 217, 0.3)",
                                    borderRadius: "10px",
                                    mt: 1,
                                }}>
                                {item}
                            </Typography>
                        ))}
                    </Stack>
                </Stack>
            ),
            in: true,
            time: new Date(),
            latest: false,
            generated: "auto",
            initial: true,
        },
    ]);

    useEffect(() => {
        if (contextStore?.claimsNew?.compare?.top_match_docs) {
            setPdfName(contextStore?.claimsNew?.compare?.pdf?.pdf_name);
            setTimeout(() => {
                const pdf_ids = [
                    ...contextStore?.claimsNew?.compare?.top_match_docs?.map((item: any) => item?.pdf_id),
                    contextStore?.claimsNew?.compare?.pdf?.pdf_id,
                ];
                socketHooks?.sendJsonMessage({
                    pdf_uuids: pdf_ids,
                    model: contextStore?.model,
                });
            }, 500);
        }
    }, [contextStore?.claimsNew?.compare]);

    const handleSend = async (value: string) => {
        msgRef.load = true;

        setMsgList([
            ...msgList?.map((item: any) => {
                item.latest = false;
                return item;
            }),
            { out: true, msg: value, time: new Date(), name: "You" },
            {
                in: true,
                msg: "",
                time: new Date(),
                latest: true,
                generated: "self",
            },
        ]);

        socketHooks?.sendJsonMessage({
            query: value,
        });
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        timeout = setTimeout(() => {
            if (!msgRef?.load) {
                setMsgList((prev: any[]) => {
                    return prev?.map((item: any) => {
                        if (item?.latest) {
                            if (typeof msgRef?.json === "object" && msgRef?.json?.response) {
                                item.msg = msgRef?.json;
                                item.latest = false;
                                msgRef.json = {};
                            }
                            return item;
                        } else {
                            return item;
                        }
                    });
                });
            }
        }, 10);

        return () => clearTimeout(timeout);
    }, [msgRef?.load]);

    return (
        <Paper elevation={3} sx={{ p: 2, borderRadius: "8px", gap: 2, display: "flex", flexDirection: "column", flex: 1 }}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography color={"#007BFF"}>{pdfName}</Typography>
                <Button
                    sx={{
                        px: 3.5,
                        py: 1,
                        background: "#007BFF",
                        color: "#fff",
                        boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
                    }}
                    variant="contained"
                    onClick={() => setFilterScreeen("auto_filter")}>
                    Back
                </Button>
            </Stack>
            <Stack ref={scrollRef} sx={{ flex: "1 0 10vh", overflowY: "auto" }}>
                {msgList?.map((elem: any, index: number) => (
                    <Stack sx={{ justifyContent: "center" }}>
                        <MessageBox
                            elem={elem}
                            msgRef={msgRef}
                            setMsgList={setMsgList}
                            lastElement={msgList?.length - 1 === index}
                            scrollRef={scrollRef}
                        />
                    </Stack>
                ))}
            </Stack>
            <Stack>
                <InputField handleSend={handleSend} />
            </Stack>
        </Paper>
    );
};

const MessageBox = ({ elem, msgRef, setMsgList, lastElement, scrollRef }: any) => {
    const [message, setMessage] = useState<any>();
    const [loading, setLoading] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (typeof elem?.msg === "object" && elem?.msg?.response?.length && lastElement && elem?.generated === "self") {
            timeout = setTimeout(() => {
                setMessage(() => {
                    return elem?.msg;
                });
            }, 20);
        } else {
            setMessage(() => {
                scrollRef?.current?.lastElementChild?.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "end",
                });
                return elem?.msg;
            });
        }
        return () => clearTimeout(timeout);
    }, [elem?.msg]);

    useEffect(() => {
        if (msgRef?.hasOwnProperty("load")) {
            setLoading(() => msgRef?.load);
        }
    }, [msgRef?.load]);

    const handleTerminateResponse = () => {
        msgRef.load = false;
        setMsgList((prev: any) => {
            if (prev?.latest) {
                prev.latest = false;
                prev.color = "#C55";
                prev.msg = "Response Terminated.";
                prev.error = true;
            }
            return prev;
        });
    };

    return (
        <Box
            sx={{
                width: "85%",
                display: "flex",
                flexDirection: "column",
                background: elem?.in ? "#fff" : "",
                py: 2,
                border: elem?.in ? "1px solid #F2F2F2" : "",
                borderRadius: elem?.in ? "10px" : "",
                position: "relative",
            }}>
            <Box sx={{ display: "flex", gap: 2, px: 2.5, alignItems: elem?.out ? "center" : "flex-start" }}>
                <Box sx={{ pt: 0.5 }}>{elem?.out ? <ProfileAvatar /> : <LogoXIcon />}</Box>
                <Box sx={{ width: "100%" }}>
                    {lastElement && loading && <Typing onClick={handleTerminateResponse} />}
                    <Typography sx={{ fontSize: "0.9rem", color: elem?.error ? elem?.color : "inherit" }}>
                        {typeof message?.response === "string"
                            ? message?.response
                                  ?.replaceAll(/[eE]\+\d{2}|\*/g, "")
                                  ?.split("\n")
                                  ?.map((msg: string) => (
                                      <>
                                          {msg}
                                          <br />
                                      </>
                                  ))
                            : message}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

const InputField = ({ handleSend }: any) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [query, setQuery] = useState<string>("");

    return (
        <OutlinedInput
            value={query}
            onChange={(e: { target: { value: string }; preventDefault: any }) => {
                setQuery(e.target.value);
            }}
            onKeyDown={(e: any) => {
                if (e?.key === "Enter") {
                    if (e?.target?.value?.length === 0) {
                        setContext({
                            ...contextStore,
                            snackState: {
                                open: true,
                                errorType: "warning",
                                message: "Please Ask A Question",
                            },
                        });
                    } else {
                        setQuery(() => "");
                        handleSend(e?.target?.value);
                    }
                }
                return;
            }}
            fullWidth
            endAdornment={
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <IconButton
                        sx={{ background: "#007BFF", "&:hover": { background: "#007BFFaa" } }}
                        onClick={() => {
                            if (query?.length === 0) {
                                setContext({
                                    ...contextStore,
                                    snackState: {
                                        open: true,
                                        errorType: "warning",
                                        message: "Please Ask A Question",
                                    },
                                });
                            } else {
                                setQuery("");
                                handleSend(query);
                            }
                        }}>
                        <SendWhiteIcon />
                    </IconButton>
                </Box>
            }
            sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "80px",
                    border: "1px solid #E0E0E0",
                },
                "& .MuiOutlinedInput-input": {
                    padding: "10px 20px",
                },
                width: "100%",
            }}
            placeholder="Ask Me Anything"
        />
    );
};

export default Chat;
