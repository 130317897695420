import { Add, Close } from "@mui/icons-material";
import { Button, IconButton, Input, LinearProgress, Modal, Stack, Typography } from "@mui/material";
import React, { useContext, useRef, useState } from "react";

import Progress from "./Progress";
import { Context } from "../../../../context/setting";
import { Api } from "../../../../apis";
import Edit from "../../../../components/Common/svgLogs/Edit";
import Upload from "../../../../components/Common/svgLogs/Upload";

const NewProject = () => {
    const inputRef = useRef<any>();
    const { contextStore, setContext } = useContext<any>(Context);
    const [open, setOpen] = useState<boolean>(false);
    const [files, setFiles] = useState<File[]>([]);
    const [response, setResponse] = useState<any>({});
    const handleClick = () => {
        if (inputRef?.current?.children[0]?.value?.trim()?.length === 0) {
            setContext({
                ...contextStore,
                snackState: {
                    open: true,
                    errorType: "error",
                    message: "Please Provide Project Name!",
                },
            });
        } else if (files?.length === 0) {
            setContext({
                ...contextStore,
                snackState: {
                    open: true,
                    errorType: "error",
                    message: "Please Add 1 File Atleast!",
                },
            });
        } else {
            const formData = new FormData();
            formData?.append("project_name", inputRef?.current?.children[0]?.value);
            formData?.append("user_id", contextStore?.profile?.userId);
            files?.forEach((item: File) => formData?.append("files", item));
            Api?.uploadClaimCreateLibraryProject(formData)?.then((res: any) => {
                if (res?.status === 200) {
                    if (Array?.isArray(res?.data)) {
                        setContext({
                            ...contextStore,
                            snackState: {
                                open: true,
                                errorType: "error",
                                message: res?.data[0],
                            },
                        });
                    } else {
                        setContext({
                            ...contextStore,
                            snackState: {
                                open: true,
                                errorType: "success",
                                message: "Files are ingesting to the database, Please wait to complete the process.",
                            },
                        });
                        setResponse(res?.data?.response);
                        Api?.getClaimsExistingProjects({ user_id: contextStore?.profile?.userId })?.then((claim: any) => {
                            if (claim?.status === 200) {
                                setOpen(false);
                                setContext({
                                    ...contextStore,
                                    claims: { ...contextStore?.claims, projects: claim?.data?.projects },
                                });
                            }
                        });
                    }
                }
            });
        }
    };

    return (
        <Stack>
            <Button
                startIcon={<Add />}
                variant="contained"
                sx={{ background: "#007BFF", "&:hover": { background: "#007BFF" }, height: "2rem" }}
                onClick={() => setOpen(true)}>
                New Project
            </Button>
            <Modal open={open} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Stack sx={{ background: "#fff", borderRadius: "10px", width: "60%", p: 2 }} gap={2}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack flex={1}>
                            <Stack direction={"row"} alignItems={"center"} gap={1}>
                                <Input ref={inputRef} sx={{ color: "#007BFF" }} />
                                <IconButton size="small" onClick={() => inputRef?.current?.click()}>
                                    <Edit />
                                </IconButton>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"} flex={1}>
                                <Typography variant="caption" color={"#9D9B9B"}>
                                    Upoad Documents or select from the <span style={{ color: "#007BFF", fontWeight: "bold" }}>Library.</span>
                                </Typography>
                                <Typography variant="caption" color={"#9D9B9B"}>
                                    {files?.length} Documents
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack>
                            <IconButton onClick={() => setOpen(false)}>
                                <Close sx={{ color: "#007BFF" }} />
                            </IconButton>
                        </Stack>
                    </Stack>
                    <Stack sx={{ height: "70vh", gap: 3 }}>
                        {files?.length > 0 && (
                            <Stack sx={{ maxHeight: "70%", overflowY: "auto", pb: 1 }}>
                                {files?.map((item) => (
                                    <Progress item={item} setFiles={setFiles} response={response} />
                                ))}
                            </Stack>
                        )}
                        <Stack
                            onDragOver={(e: React.DragEvent) => {
                                e?.preventDefault();
                            }}
                            onDrop={(e: React.DragEvent) => {
                                e.preventDefault();
                                const droppedFiles = Array.from(e.dataTransfer.files);
                                const acceptedFiles = droppedFiles.filter((file) => file.type === "application/pdf");
                                setFiles((prev) => Array.from(new Set([...prev, ...acceptedFiles])));
                            }}
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{ border: "1px dashed #007BFF", borderRadius: "8px", cursor: "pointer", flex: 1 }}
                            component={"label"}
                            tabIndex={1}>
                            <input
                                hidden
                                multiple
                                type="file"
                                accept=".pdf"
                                onChange={(e: { target: { files: any } }) => setFiles((prev) => Array?.from(new Set([...prev, ...e?.target?.files])))}
                            />
                            <Stack direction={"row"} alignItems={"center"} gap={2}>
                                <Upload />
                                <Stack>
                                    <Typography>
                                        Drag and Drop or <span style={{ color: "#007BFF", fontWeight: "bold" }}>Browse</span>
                                    </Typography>
                                    <Typography variant="caption">Supported .pdf</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"flex-end"} gap={3}>
                        <Button variant="outlined" sx={{ color: "#007BFF" }} onClick={() => setOpen(false)}>
                            Go Back
                        </Button>
                        <Button sx={{ background: "#007BFF" }} variant="contained" disabled={files?.length === 0} onClick={handleClick}>
                            Upload
                        </Button>
                    </Stack>
                </Stack>
            </Modal>
        </Stack>
    );
};

export default NewProject;
