import { Button, Grid, Paper, Skeleton, Stack, Switch, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import Preview from "./Preview/Preview";
import AutoFilter from "./AutoFilter";
import Compare from "./Compare";
import Chat from "./Chat";
import { Context } from "../../../../context/setting";
import { Api } from "../../../../apis";

const Files = () => {
    const [filterScreeen, setFilterScreeen] = useState<string>("init");
    switch (filterScreeen) {
        case "init":
            return <Init setFilterScreeen={setFilterScreeen} />;
        case "auto_filter":
            return <AutoFilter setFilterScreeen={setFilterScreeen} />;
        case "compare":
            return <Compare setFilterScreeen={setFilterScreeen} />;
        case "chat":
            return <Chat setFilterScreeen={setFilterScreeen} />;

        default:
            return <Init setFilterScreeen={setFilterScreeen} />;
    }
};

const Init = ({ setFilterScreeen }: { setFilterScreeen: any }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [loader, setLoader] = useState<boolean>(false);
    const [fileList, setFileList] = useState<any[]>([]);
    const [selectedFile, setSelectedFile] = useState<any>({});
    const [cliamFilter, setCliamFilter] = useState<boolean>(false);

    useEffect(() => {
        fetchFiles(cliamFilter);
    }, []);

    const fetchFiles = (status: boolean) => {
        setLoader(true);
        Api?.getClaimsExistingProjectsPdf({ project_id: contextStore?.claimsNew?.selectedPorject?.project_id })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    setFileList((prev: any[]) => {
                        if (status) {
                            return res?.data?.pdfs?.filter((item: any) => item?.claim_status === "Claim");
                        }
                        return res?.data?.pdfs;
                    });
                    setContext({ ...contextStore, claimsNew: { ...contextStore?.claimsNew, filterDocuments: res?.data?.pdfs } });
                }
                setLoader(false);
            })
            .catch((err) => setLoader(false));
    };

    return (
        <Grid container spacing={2} flex={1}>
            <Grid item xs={12} md={4}>
                <Paper elevation={3} sx={{ p: 2, borderRadius: "8px", gap: 2, display: "flex", flexDirection: "column", height: "100%" }}>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography color={"#007BFF"}>Document List</Typography>
                        <Stack direction={"row"} alignItems={"center"}>
                            <Switch
                                disabled={loader}
                                size="small"
                                value={cliamFilter}
                                onChange={(e: { target: { checked: boolean } }) => {
                                    setCliamFilter((prev: boolean) => {
                                        fetchFiles(e?.target?.checked);
                                        return e?.target?.checked;
                                    });
                                }}
                            />
                            <Typography variant="caption" sx={{ fontSize: "0.8rem" }}>
                                Claim Document
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack flex={1} justifyContent={"space-between"}>
                        <Stack sx={{ flex: "1 0 10vh", overflowY: "auto", gap: 1 }}>
                            {loader ? (
                                <Stack gap={1}>
                                    {Array(3)
                                        ?.fill(0)
                                        ?.map(() => (
                                            <Skeleton variant="rectangular" sx={{ height: "3rem", borderRadius: "8px" }} />
                                        ))}
                                </Stack>
                            ) : (
                                fileList?.map((doc) => (
                                    <Tooltip title={doc?.pdf_name} placement="right">
                                        <Stack
                                            sx={{
                                                background: selectedFile?.pdf_name === doc?.pdf_name ? "#E3F4FF" : "#F9F9F9",
                                                borderRadius: "8px",
                                                p: 1,
                                                "& *": { color: selectedFile?.pdf_name === doc?.pdf_name ? "#007BFF" : "inherit" },
                                                cursor: "pointer",
                                            }}
                                            onClick={() => setSelectedFile(doc)}>
                                            <Typography variant="caption" sx={{ fontWeight: "bold" }} noWrap>
                                                {doc?.pdf_name}
                                            </Typography>
                                            <Typography variant="caption">{doc?.claim_status}</Typography>
                                        </Stack>
                                    </Tooltip>
                                ))
                            )}
                        </Stack>
                        <Stack alignItems={"center"}>
                            <Button
                                disabled={!cliamFilter}
                                sx={{
                                    px: 3.5,
                                    py: 1,
                                    background: "#007BFF",
                                    color: "#fff",
                                    boxShadow:
                                        "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
                                    width: "12rem",
                                }}
                                variant="contained"
                                onClick={() => setFilterScreeen("auto_filter")}>
                                Auto-Filter
                            </Button>
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12} md={8} sx={{ display: "flex", flexDirection: "column" }}>
                <Preview selectedFile={selectedFile} fileList={fileList} />
            </Grid>
        </Grid>
    );
};

export default Files;
