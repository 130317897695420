import { Table, TableRow, TableCell, Skeleton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../../context/setting";

const Parties = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [letterCount, setLetterCount] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const fetchLetterCount = (data?: any) => {
        setLoading(true);
        setTimeout(() => {
            setLetterCount(data);
            setLoading(false);
        }, 2000);
    };

    useEffect(() => {
        if (contextStore?.claimsNew?.parties) {
            fetchLetterCount(contextStore?.claimsNew?.parties || {});
        }
        return () => {};
    }, [contextStore?.claimsNew?.parties]);

    return (
        <Table>
            <TableRow>
                {["Document", "Contractor", "Engineer", "Employer"]?.map((head) => (
                    <TableCell
                        sx={{ border: "1px solid #D9D9D9", background: "#E3F4FF", color: "#007BFF", py: "5px" }}
                        size="small"
                    >
                        {head}
                    </TableCell>
                ))}
            </TableRow>
            {[
                { Document: "Letters", ...(Object?.keys(letterCount)?.length > 0 ? letterCount : {}) },
                { Document: "SRR’s", Contractor: "--", Engineer: "--", Employer: "--" },
                { Document: "Emails", Contractor: "--", Engineer: "--", Employer: "--" },
            ]?.map((row: any) => (
                <TableRow>
                    {["Document", "Contractor", "Engineer", "Employer"]?.map((head) =>
                        loading ? (
                            <TableCell size="small" sx={{ border: "1px solid #D9D9D9", fontSize: "0.8rem", p: 0 }}>
                                <Skeleton variant="rectangular" sx={{ height: "2rem" }} />
                            </TableCell>
                        ) : (
                            <TableCell size="small" sx={{ border: "1px solid #D9D9D9", fontSize: "0.8rem", py: "5px" }}>
                                {row[head]}
                            </TableCell>
                        )
                    )}
                </TableRow>
            ))}
        </Table>
    );
};

export default Parties;
