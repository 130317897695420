import { Paper, Typography, Stack, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Api } from "../../../../../apis";

const Summary = ({ selectedFile }: { selectedFile: any }) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [summary, setSummary] = useState<any>({});

    useEffect(() => {
        if (selectedFile?.pdf_id) {
            handleSummary(selectedFile?.pdf_id);
        }
    }, [selectedFile]);

    const handleSummary = (pdf_id: string) => {
        setLoader(true);
        Api?.getClaimsExistingProjectsPdfSummary({ pdf_id })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    setSummary(res?.data);
                } else {
                    setSummary({});
                }
                setLoader(false);
            })
            ?.catch((err) => {
                setLoader(false);
                setSummary({});
            });
    };

    return (
        <Paper
            elevation={3}
            sx={{
                px: 2,
                py: 1,
                borderRadius: "8px",
                gap: 2,
                display: "flex",
                flexDirection: "column",
                flex: "1 0 10vh",
                overflowY: "auto",
            }}>
            <Typography color={"#007BFF"}>Summary</Typography>
            {loader ? (
                <Stack gap={1}>
                    <Skeleton variant="text"></Skeleton>
                    <Skeleton variant="text" width={"90%"}></Skeleton>
                    <Skeleton variant="text" width={"80%"}></Skeleton>
                    <Skeleton variant="text" width={"60%"}></Skeleton>
                    <Skeleton variant="text" width={"40%"}></Skeleton>
                    <Skeleton variant="text" width={"20%"}></Skeleton>
                </Stack>
            ) : (
                <Typography variant="caption">
                    {summary?.Summary?.split("\n")?.map((msg: string) => (
                        <>
                            {msg} <br />
                        </>
                    ))}
                </Typography>
            )}
            <Typography color={"#007BFF"}>Problem Statement</Typography>
            {loader ? (
                <Stack gap={1}>
                    <Skeleton variant="text"></Skeleton>
                    <Skeleton variant="text" width={"90%"}></Skeleton>
                    <Skeleton variant="text" width={"80%"}></Skeleton>
                    <Skeleton variant="text" width={"60%"}></Skeleton>
                    <Skeleton variant="text" width={"40%"}></Skeleton>
                    <Skeleton variant="text" width={"20%"}></Skeleton>
                </Stack>
            ) : (
                <Typography variant="caption">
                    {summary["Problem Statement"]?.split("\n")?.map((msg: string) => (
                        <>
                            {msg} <br />
                        </>
                    ))}
                </Typography>
            )}
        </Paper>
    );
};

export default Summary;
