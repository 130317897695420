import { Button, Paper, Stack } from "@mui/material";
import React, { useState } from "react";
import Summary from "./Summary";
import Reference from "./Reference";
import Document from "./Document";

const Preview = ({ selectedFile, fileList }: { selectedFile: any; fileList: any[] }) => {
    const [viewType, setViewType] = useState<string>("Preview");

    if (selectedFile?.pdf_id) {
        return (
            <Stack gap={2} flex={1}>
                <Stack direction={"row"} gap={2}>
                    {["Preview", "View Summary", "References"]?.map((value) => (
                        <Button
                            sx={{
                                px: 1.5,
                                py: 1,
                                background: viewType === value ? "#E3F4FF" : "#fff",
                                color: viewType === value ? "#007BFF" : "#inherit",
                                boxShadow:
                                    "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
                            }}
                            onClick={() => setViewType(value)}
                        >
                            {value}
                        </Button>
                    ))}
                </Stack>
                {viewType === "View Summary" && <Summary selectedFile={selectedFile} />}
                {viewType === "References" && <Reference fileList={fileList} selectedFile={selectedFile} />}
                {viewType === "Preview" && <Document selectedFile={selectedFile} />}
            </Stack>
        );
    }
    return (
        <Paper
            elevation={3}
            sx={{
                p: 2,
                borderRadius: "8px",
                gap: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                fontWeight: "bold",
            }}
        >
            Please Select A File
        </Paper>
    );
};

export default Preview;
