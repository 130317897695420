import React from "react";

const DoneTick = () => {
    return (
        <svg width="14" height="18" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_12365_1349)">
                <path
                    d="M9 0C9.82617 0 10.623 0.105469 11.3906 0.316406C12.1582 0.527344 12.873 0.832031 13.5352 1.23047C14.1973 1.62891 14.8037 2.09766 15.3545 2.63672C15.9053 3.17578 16.377 3.78223 16.7695 4.45605C17.1621 5.12988 17.4639 5.84766 17.6748 6.60938C17.8857 7.37109 17.9941 8.16797 18 9C18 9.82617 17.8945 10.623 17.6836 11.3906C17.4727 12.1582 17.168 12.873 16.7695 13.5352C16.3711 14.1973 15.9023 14.8037 15.3633 15.3545C14.8242 15.9053 14.2178 16.377 13.5439 16.7695C12.8701 17.1621 12.1523 17.4639 11.3906 17.6748C10.6289 17.8857 9.83203 17.9941 9 18C8.17383 18 7.37695 17.8945 6.60938 17.6836C5.8418 17.4727 5.12695 17.168 4.46484 16.7695C3.80273 16.3711 3.19629 15.9023 2.64551 15.3633C2.09473 14.8242 1.62305 14.2178 1.23047 13.5439C0.837891 12.8701 0.536133 12.1523 0.325195 11.3906C0.114258 10.6289 0.00585938 9.83203 0 9C0 8.17383 0.105469 7.37695 0.316406 6.60938C0.527344 5.8418 0.832031 5.12695 1.23047 4.46484C1.62891 3.80273 2.09766 3.19629 2.63672 2.64551C3.17578 2.09473 3.78223 1.62305 4.45605 1.23047C5.12988 0.837891 5.84766 0.536133 6.60938 0.325195C7.37109 0.114258 8.16797 0.00585938 9 0ZM14.2998 6.02051L13.1045 4.8252L7.3125 10.6172L4.89551 8.2002L3.7002 9.39551L7.3125 13.0078L14.2998 6.02051Z"
                    fill="#12924F"
                />
            </g>
            <defs>
                <clipPath id="clip0_12365_1349">
                    <rect width="19" height="19" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default DoneTick;
