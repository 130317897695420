import { Stack, Grid, Paper, Typography, IconButton } from "@mui/material";
import React from "react";
import Parties from "./Parties";
import Chronology from "./Chronology";
import Clauses from "./Clauses";
import DelayEvent from "./DelayEvent";
import Redirect from "../../../../components/Common/svgLogs/Redirect";

const Overview = ({ setSelectedTab }: { setSelectedTab: any }) => {
    return (
        <Stack flex={1} gap={2} direction={"row"} flexWrap={"wrap"}>
            <Stack sx={{ flex: { md: 2, xs: 1 } }} gap={2} direction={"row"} flexWrap={"wrap"}>
                <Stack sx={{ width: { xs: "100%" }, flex: { md: 1 }, minHeight: { md: "10rem" } }}>
                    <Paper elevation={3} sx={{ flex: 1, p: 2, borderRadius: "8px", gap: 2, display: "flex", flexDirection: "column" }}>
                        <Typography color={"#007BFF"}>Documents by parties</Typography>
                        <Stack>
                            <Parties />
                        </Stack>
                    </Paper>
                </Stack>
                <Stack sx={{ width: { xs: "100%" }, flex: { md: 1 }, minHeight: { md: "10rem" } }}>
                    <Paper
                        elevation={3}
                        sx={{ flex: 1, p: 2, borderRadius: "8px", display: "flex", flexDirection: "column", position: "relative", gap: 0.5 }}>
                        <Stack>
                            <Typography color={"#007BFF"}>Clauses referred by Contractor</Typography>
                            <Stack sx={{ position: "absolute", top: "0px", right: "0px" }}>
                                <IconButton onClick={() => setSelectedTab("clauses")}>
                                    <Redirect />
                                </IconButton>
                            </Stack>
                        </Stack>
                        <Clauses />
                    </Paper>
                </Stack>
                <Stack sx={{ width: { xs: "100%" } }}>
                    <Paper
                        elevation={3}
                        sx={{
                            p: 2,
                            borderRadius: "8px",
                            gap: 2,
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                        }}>
                        <Stack>
                            <Stack sx={{ position: "absolute", top: "0px", right: "0px" }}>
                                <IconButton onClick={() => setSelectedTab("chronology")}>
                                    <Redirect />
                                </IconButton>
                            </Stack>
                            <Typography color={"#007BFF"}>
                                Chronology <span style={{ fontSize: "0.9rem", color: "#484848" }}>( Top 10 Results )</span>
                            </Typography>
                        </Stack>
                        <Stack sx={{ flex: "1 0 33vh", overflow: "auto", width: { md: "calc(100vw - 38rem)" } }}>
                            <Chronology />
                        </Stack>
                    </Paper>
                </Stack>
            </Stack>
            <Stack sx={{ width: { xs: "100%" }, flex: { md: 1 } }}>
                <Paper
                    elevation={3}
                    sx={{
                        p: 2,
                        borderRadius: "8px",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        height: "100%",
                        gap: 2,
                    }}>
                    <Stack>
                        <Typography color={"#007BFF"}>Delay Events</Typography>
                        <Stack sx={{ position: "absolute", top: "0px", right: "0px" }}>
                            <IconButton onClick={() => setSelectedTab("delay_events")}>
                                <Redirect />
                            </IconButton>
                        </Stack>
                    </Stack>
                    <DelayEvent />
                </Paper>
            </Stack>
        </Stack>
    );
};

export default Overview;
