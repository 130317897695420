import { Skeleton, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../../context/setting";
import { Api } from "../../../../apis";


const DelayEvent = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [tableData, setTableData] = useState<any>([]);
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoader(true);
        Api?.getClaimsExistingDelayEvent({
            user_id: contextStore?.profile?.userId,
            table_name: "de_period_result",
            filter_dict: {
                max_rows: "4",
            },
        })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    setTableData(JSON?.parse(res?.data?.de_period_result));
                } else {
                    setTableData([]);
                }
                setLoader(false);
            })
            ?.catch((err) => setLoader(false));
    };

    return loader ? (
        <Stack gap={1}>
            {Array(4)
                ?.fill(0)
                ?.map((_) => (
                    <Skeleton variant="rounded" height={"2.5rem"} />
                ))}
        </Stack>
    ) : (
        <Stack gap={1}>
            {tableData?.map((step: any, index: number) => (
                <Stack ml={3} sx={{ background: "rgba(190, 223, 241, 0.59)", borderRadius: "8px", py: 1, px: 2, position: "relative" }}>
                    <div
                        style={{
                            position: "absolute",
                            left: "-27.5px",
                            alignSelf: "center",
                            border: "1px solid #007BFF",
                            padding: "4px",
                            borderRadius: "50%",
                        }}>
                        <div style={{ width: "8px", height: "8px", borderRadius: "50%", background: "#007BFF" }} />
                    </div>
                    {index !== tableData?.length - 1 && (
                        <div
                            style={{
                                width: "2px",
                                height: "100%",
                                background: "#007BFF",
                                position: "absolute",
                                left: "-19.5px",
                                top: "16px",
                            }}
                        />
                    )}
                    <Typography sx={{ fontSize: "0.95rem" }}>{step?.["Delay Event"]}</Typography>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography sx={{ fontSize: "0.75rem" }}>Start End {step?.["Delay Start Date"]}</Typography>
                        <Typography sx={{ fontSize: "0.75rem" }}>End Date {step?.["Delay End Date"]}</Typography>
                    </Stack>
                </Stack>
            ))}
        </Stack>
    );
};

export default DelayEvent;
