import React, { useContext, useEffect, useState } from "react";

import { Button, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Context } from "../../../../context/setting";
import { Api } from "../../../../apis";

const Compare = ({ setFilterScreeen }: { setFilterScreeen: any }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [compareDoc, setCompareDoc] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [pdfName, setPdfName] = useState<string>("");

    useEffect(() => {
        fetchCompare();
    }, [contextStore?.claimsNew?.compare]);

    const fetchCompare = () => {
        setLoader(true);
        setPdfName(contextStore?.claimsNew?.compare?.pdf?.pdf_name);
        Api?.getClaimsExistingProjectsPdfCompare({
            claim_docs: [contextStore?.claimsNew?.compare?.pdf?.pdf_id],
            check_docs: contextStore?.claimsNew?.compare?.top_match_docs?.map((item: any) => item?.pdf_id),
            model: contextStore?.model,
        })
            ?.then((res: any) => {
                if (res?.status === 200 && Array?.isArray(res?.data)) {
                    setCompareDoc(res?.data);
                }
                setLoader(false);
            })
            ?.catch((err) => setLoader(false));
    };
    return (
        <Paper elevation={3} sx={{ p: 2, borderRadius: "8px", gap: 2, display: "flex", flexDirection: "column", flex: 1 }}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography color={"#007BFF"}>{pdfName}</Typography>
                <Button
                    sx={{
                        px: 3.5,
                        py: 1,
                        background: "#007BFF",
                        color: "#fff",
                        boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
                    }}
                    variant="contained"
                    onClick={() => setFilterScreeen("auto_filter")}>
                    Back
                </Button>
            </Stack>
            <Stack sx={{ flex: "1 0 10vh", overflowY: "auto", width: "92vw" }}>
                <Table stickyHeader>
                    <thead>
                        <tr>
                            {["Relationships", "Details", "Verification", "Missed Details", "Document 1", "Document 2"]?.map((head) => (
                                <th
                                    style={{
                                        border: "1px solid #D9D9D9",
                                        padding: "0.3rem 0.6rem",
                                        fontSize: "0.8rem",
                                        minWidth: "3rem",
                                        color: "#007BFF",
                                        background: "#E3F4FF",
                                        cursor: "pointer",
                                        position: "sticky",
                                        top: 0,
                                        height: "1.5rem",
                                        zIndex: 100 + compareDoc?.length,
                                    }}>
                                    {head}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {loader
                            ? Array(7)
                                  ?.fill(0)
                                  ?.map((_) => (
                                      <tr>
                                          {Array(6)
                                              ?.fill(0)
                                              ?.map((_) => (
                                                  <TableCell size="small" sx={{ border: "1px solid #D9D9D9", fontSize: "0.8rem", p: 0 }}>
                                                      <Skeleton variant="rectangular" sx={{ height: "2rem" }} />
                                                  </TableCell>
                                              ))}
                                      </tr>
                                  ))
                            : compareDoc?.map((item: any) => (
                                  <tr>
                                      {[
                                          item?.Relationships,
                                          item?.Details,
                                          item?.Verified?.toString(),
                                          item?.Missed_Details,
                                          item?.Document_1?.Name,
                                          item?.Document_2?.Name,
                                      ]?.map((body: any, index: number) => (
                                          <td
                                              style={{
                                                  border: "1px solid #D9D9D9",
                                                  padding: "0.3rem 0.6rem",
                                                  fontSize: "0.8rem",
                                                  verticalAlign: "baseline",
                                                  position: "sticky",
                                                  top: "3rem",
                                                  background: "#fff",
                                                  zIndex: 100 + index,
                                              }}>
                                              {body}
                                          </td>
                                      ))}
                                  </tr>
                              ))}
                    </tbody>
                </Table>
                {compareDoc?.length === 0 && !loader && (
                    <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
                        <Typography>No Data Found</Typography>
                    </Stack>
                )}
            </Stack>
        </Paper>
    );
};

export default Compare;
