import { Paper, Skeleton, Stack, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { Document as Viewer, Page, pdfjs } from "react-pdf";
import { Context } from "../../../../../context/setting";
import { Api } from "../../../../../apis";

const Document = ({ selectedFile }: { selectedFile: any }) => {
    const { contextStore } = useContext<any>(Context);
    const [loader, setLoader] = useState<boolean>(false);
    const [url, setUrl] = useState<string | undefined>();
    const [pages, setPages] = useState<number>(1);

    useEffect(() => {
        fetchDocument(selectedFile?.pdf_id);
    }, [selectedFile]);

    const fetchDocument = useCallback(
        (id: string) => {
            pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
            setLoader(true);
            Api?.document({
                get_file: id,
                user_id: contextStore?.profile?.userId,
                file_type: "pdf",
            })
                ?.then((res: any) => {
                    if (res?.status === 200) {
                        const blob = new Blob([res?.data], { type: "application/pdf" });
                        const url = URL?.createObjectURL(blob);
                        setUrl(url);
                    } else {
                        setUrl(undefined);
                    }
                    setLoader(false);
                })
                ?.catch((err) => setLoader(false));
        },
        [selectedFile]
    );

    return (
        <Paper
            elevation={3}
            sx={{
                px: 2,
                py: 1,
                borderRadius: "8px",
                gap: 2,
                display: "flex",
                flexDirection: "column",
                flex: 1,
            }}>
            <Typography color={"#007BFF"}>Preview</Typography>
            <Stack
                flex={1}
                sx={{ flex: "1 0 10vh", overflowY: "auto", boxShadow: "inset 0px 2px 2px 0px rba(0,0,0,0.3),inset 0px -2px 2px 0px rba(0,0,0,0.3)" }}>
                {loader ? (
                    <Stack sx={{ flex: 1, gap: 2.5 }}>
                        {Array(3)
                            ?.fill(0)
                            ?.map((_) => (
                                <Stack gap={1}>
                                    {["100%", "70%", "50%", "30%"]?.map((item) => (
                                        <Skeleton variant="rectangular" width={item} sx={{ borderRadius: "8px" }}></Skeleton>
                                    ))}
                                </Stack>
                            ))}
                    </Stack>
                ) : (
                    <Viewer file={url} onLoadSuccess={({ numPages }) => setPages(numPages)}>
                        {Array(pages)
                            ?.fill(0)
                            ?.map((_, index: number) => (
                                <Page pageNumber={index + 1} renderTextLayer={false} renderAnnotationLayer={false}></Page>
                            ))}
                    </Viewer>
                )}
            </Stack>
        </Paper>
    );
};

export default Document;
