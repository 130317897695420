import { Paper, Typography, Stack, Skeleton, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Api } from "../../../../../apis";

const Reference = ({ fileList, selectedFile }: { fileList: any[]; selectedFile: any }) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [references, setReferences] = useState<any[]>();

    useEffect(() => {
        handleReferences({ pdf_ids: fileList?.map((item) => item?.pdf_id), filter_pdf_id: selectedFile?.pdf_id });
    }, [selectedFile]);

    const handleReferences = (data: any) => {
        setLoader(true);
        Api?.getClaimsExistingProjectsPdfReference(data)
            ?.then((res: any) => {
                if (res?.status == 200) {
                    setReferences(JSON?.parse(res?.data));
                } else {
                    setReferences([]);
                }
                setLoader(false);
            })
            ?.catch((err) => {
                setLoader(false);
                setReferences([]);
            });
    };

    return (
        <Paper
            elevation={3}
            sx={{
                px: 2,
                py: 1,
                borderRadius: "8px",
                gap: 2,
                display: "flex",
                flexDirection: "column",
                flex: "1 0 10vh",
                overflowY: "auto",
                position: "relative",
            }}>
            <Typography color={"#007BFF"}>Reference</Typography>
            {loader ? (
                <Stack gap={1}>
                    <Skeleton variant="text"></Skeleton>
                    <Skeleton variant="text" width={"90%"}></Skeleton>
                    <Skeleton variant="text" width={"80%"}></Skeleton>
                    <Skeleton variant="text" width={"60%"}></Skeleton>
                    <Skeleton variant="text" width={"40%"}></Skeleton>
                    <Skeleton variant="text" width={"20%"}></Skeleton>
                </Stack>
            ) : (
                <Stack sx={{ flex: "1 0 10vh", overflowY: "auto" }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {["Reference", "Starting date", "Planned Ending date", "Documents Mentioned in Details"]?.map((head) => (
                                    <TableCell
                                        sx={{
                                            border: "1px solid #D9D9D9",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            background: "#F9F9F9",
                                            fontSize: "0.9rem",
                                            py: 0.5,
                                        }}>
                                        {head}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {references?.map((item: any) => (
                                <TableRow>
                                    {["Reference", "Starting Date", "Planned Ending Date", "Documents Mentioned in"]?.map((body) => (
                                        <TableCell sx={{ border: "1px solid #D9D9D9", fontSize: "0.85rem", py: 0.5 }}>{item[body]}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Stack>
            )}
        </Paper>
    );
};

export default Reference;
