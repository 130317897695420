import {
    Stack,
    Table,
    TableCell,
    Button,
    Skeleton,
    Paper,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    CircularProgress,
    Pagination,
    Typography,
} from "@mui/material";
import React, { lazy, startTransition, useContext, useEffect, useRef, useState } from "react";

import DownloadExcel from "../Common/DownloadExcel";
import { Context } from "../../../../context/setting";
import { Api, source } from "../../../../apis";
import Expand from "../../../../components/Common/svgLogs/Expand";
import Collapse from "../../../../components/Common/svgLogs/Collapse";
const Tables = lazy(() => import("./Tables"));

const Chronology = () => {
    const tableRef = useRef<any>();
    const pageRef = useRef<any>();
    const { contextStore, setContext } = useContext<any>(Context);
    const [expand, setExpand] = useState<boolean>(true);
    const [tableData, setTableData] = useState<any>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [filters, setFilters] = useState<any>({
        package: "",
        party: "",
        delayEvent: "",
        sub_delayEvent: [],
        load: false,
        letter: "",
    });
    const [parties, setParties] = useState<any>({});
    const sourceRef = useRef(source?.source());

    useEffect(() => {
        sourceRef.current = source.source();
        fetchData(sourceRef?.current?.token);
        return () => sourceRef?.current?.cancel();
    }, []);

    const fetchData = (token?: any) => {
        setLoader(true);
        const filterPayload = {
            table_name: "chronology",
            filter_dict: {
                page: pageRef?.current?.page?.toString() || "1",
                ...(filters?.party !== "" ? { Party: filters?.party } : {}),
                ...(filters?.delayEvent !== "" ? { "Delay Event": filters?.delayEvent } : {}),
                ...(filters?.responseTime !== "" ? { "Response Time": filters?.responseTime } : {}),
                ...(filters?.sub_delay !== "" ? { "Sub Delay Event": filters?.sub_delay } : {}),
            },
        };
        Api?.getClaimsExistingDelayEvent({ user_id: contextStore?.profile?.userId, ...filterPayload }, token)
            ?.then((res: any) => {
                if (res?.status === 200) {
                    pageRef.current.total = res?.data?.total_pages;
                    setTableData(JSON?.parse(res?.data?.chronology));
                    setParties(res?.data?.parties);
                } else {
                    setTableData([]);
                }
                setLoader(false);
            })
            ?.catch((err) => setLoader(false));
    };

    const fetchSubDelayEvent = (value: string) => {
        setFilters((prev: any) => {
            return { ...prev, load: true };
        });
        Api?.getSubDelayEvent({ delay_event: value, user_id: contextStore?.profile?.userId })
            ?.then((res: any) => {
                let data: any[] = [];
                if (res?.status === 200 && typeof res?.data === "object") {
                    data = res?.data?.sub_delay_event?.map((item: any) => item?.toString() || "null");
                }
                setFilters((prev: any) => {
                    return { ...prev, load: false, sub_delayEvent: data };
                });
            })
            ?.catch((err) => {
                setFilters((prev: any) => {
                    return { ...prev, load: false, sub_delayEvent: [] };
                });
            });
    };

    return (
        <Stack gap={2} flex={1}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <FormControl size="small">
                        <InputLabel sx={{ top: "-3px", fontSize: "0.9rem" }}>Package</InputLabel>
                        <Select
                            value={filters?.package}
                            onChange={(e: { target: { value: string } }) =>
                                setFilters((prev: any) => ({ ...prev, package: e?.target?.value }))
                            }
                            label={"Package"}
                            size="small"
                            sx={{
                                minWidth: "8rem",
                                fontSize: "0.9rem",
                                fontFamily: "Gilroy-Medium",
                                height: "2rem",
                                background: "#fff",
                            }}
                        >
                            <MenuItem value={""} sx={{ fontStyle: "italic" }}>
                                N/A
                            </MenuItem>
                            <MenuItem value={"C4"}>C4</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl size="small">
                        <InputLabel sx={{ top: "-3px", fontSize: "0.9rem" }}>Party</InputLabel>
                        <Select
                            value={filters?.party}
                            onChange={(e: { target: { value: string } }) =>
                                setFilters((prev: any) => ({ ...prev, party: e?.target?.value }))
                            }
                            size="small"
                            label="Party"
                            sx={{
                                minWidth: "8rem",
                                fontSize: "0.9rem",
                                fontFamily: "Gilroy-Medium",
                                height: "2rem",
                                background: "#fff",
                            }}
                        >
                            <MenuItem value={""} sx={{ fontStyle: "italic" }}>
                                N/A
                            </MenuItem>
                            {["NHSRCL", "LTC", "TCAP"]?.map((item) => <MenuItem value={item}>{item}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl size="small">
                        <InputLabel sx={{ top: "-3px", fontSize: "0.9rem" }}>Delay Event</InputLabel>
                        <Select
                            value={filters?.delayEvent}
                            onChange={(e: { target: { value: string } }) => {
                                fetchSubDelayEvent(e?.target?.value);
                                setFilters((prev: any) => ({ ...prev, delayEvent: e?.target?.value }));
                            }}
                            size="small"
                            label="Delay Event"
                            sx={{
                                minWidth: "8rem",
                                fontSize: "0.9rem",
                                fontFamily: "Gilroy-Medium",
                                height: "2rem",
                                background: "#fff",
                            }}
                        >
                            <MenuItem value={""} sx={{ fontStyle: "italic" }}>
                                N/A
                            </MenuItem>
                            {delay_event?.map((item) => <MenuItem value={item}>{item}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl size="small">
                        <InputLabel sx={{ top: "-3px", fontSize: "0.9rem" }}>Sub Delay Event</InputLabel>
                        <Select
                            disabled={filters?.load}
                            endAdornment={
                                filters?.load ? (
                                    <div>
                                        <CircularProgress style={{ width: "16px", height: "16px" }} />
                                    </div>
                                ) : (
                                    <></>
                                )
                            }
                            value={filters?.sub_delay || ""}
                            onChange={(e: { target: { value: string } }) =>
                                setFilters((prev: any) => ({ ...prev, sub_delay: e?.target?.value }))
                            }
                            size="small"
                            label="Sub Delay Event"
                            sx={{
                                minWidth: "10rem",
                                fontSize: "0.9rem",
                                fontFamily: "Gilroy-Medium",
                                height: "2rem",
                                background: "#fff",
                            }}
                        >
                            <MenuItem value={""} sx={{ fontStyle: "italic" }}>
                                N/A
                            </MenuItem>
                            {filters?.sub_delayEvent?.map((item: any) => <MenuItem value={item}>{item}</MenuItem>)}
                        </Select>
                    </FormControl>
                    {["Contractor", "Engineer", "Employer"]?.map((party) => (
                        <Typography fontSize={"small"} fontWeight={500}>
                            {party} : <span>{parties[party]}</span>
                        </Typography>
                    ))}
                </Stack>
                <Stack direction={"row"} gap={2} alignItems={"center"}></Stack>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <FormControl size="small">
                        <InputLabel sx={{ top: "-3px", fontSize: "0.9rem" }}>Letter No.</InputLabel>
                        <Select
                            value={filters?.letter}
                            onChange={(e: { target: { value: string } }) =>
                                setFilters((prev: any) => ({ ...prev, letter: e?.target?.value }))
                            }
                            label={"Letter No."}
                            size="small"
                            sx={{
                                minWidth: "8rem",
                                fontSize: "0.9rem",
                                fontFamily: "Gilroy-Medium",
                                height: "2rem",
                                background: "#fff",
                            }}
                        >
                            <MenuItem value={""} sx={{ fontStyle: "italic" }}>
                                N/A
                            </MenuItem>
                            <MenuItem value={"eot-3"}>EOT 3</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        sx={{
                            height: "2rem",
                            px: 3.5,
                            background: "#007BFF",
                            color: "#fff",
                            boxShadow:
                                "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
                        }}
                        onClick={() => {
                            sourceRef.current = source.source();
                            fetchData(sourceRef?.current?.token);
                        }}
                    >
                        Filter
                    </Button>
                    <DownloadExcel name="chronology" disable={loader} />
                </Stack>
            </Stack>
            <Paper
                elevation={3}
                sx={{
                    p: 2,
                    borderRadius: "8px",
                    gap: 2,
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Stack
                    sx={{
                        pr: 1,
                        flex: "1 0 10vh",
                        overflow: "auto",
                        width: { xs: "calc(100vw - 0px)", sm: "calc(100vw - 120px)" },
                    }}
                >
                    <Table ref={tableRef} sx={{ "& th": { whiteSpace: "nowrap" } }}>
                        <thead>
                            <tr>
                                <th
                                    style={{
                                        border: "1px solid #D9D9D9",
                                        padding: "0.3rem 0.6rem",
                                        fontSize: "0.8rem",
                                        minWidth: "3rem",
                                        color: "#007BFF",
                                        background: "#E3F4FF",
                                        cursor: "pointer",
                                        position: "sticky",
                                        top: 0,
                                        height: "1.5rem",
                                        zIndex: 100 + tableData?.length,
                                        whiteSpace: "nowrap",
                                    }}
                                    rowSpan={2}
                                    onClick={() => setExpand((prev: boolean) => !prev)}
                                >
                                    {expand ? <Expand /> : <Collapse />}
                                </th>
                                {[
                                    { title: "Sn.", row: 2 },
                                    { title: "Delay Event", col: 2 },
                                    { title: "Party", row: 2 },
                                    { title: "Department", row: 2 },
                                    { title: "Previous Reference Letter", row: 2 },
                                    { title: "Letter Reference No.", row: 2 },
                                    { title: "Date", row: 2 },
                                    { title: "Response Letter(s)", row: 2 },
                                    { title: "Response Letter's Date", row: 2 },
                                    { title: "Response Time", row: 2 },
                                    { title: "Referred In Claims", row: 2 },
                                    { title: "Missing in Claims", row: 2 },
                                    { title: "Letter Type", row: 2 },
                                    { title: "Gist", row: 2 },
                                    { title: "Contradictions wrt the (Contract / FIDIC (Yellow / Pink Book)", col: 1 },
                                    { title: "Contradictions wrt the Party's Letter", col: 2 },
                                ]?.map((head) => (
                                    <th
                                        style={{
                                            border: "1px solid #D9D9D9",
                                            padding: "0.3rem 0.6rem",
                                            fontSize: "0.8rem",
                                            minWidth: "3rem",
                                            color: "#007BFF",
                                            background: "#E3F4FF",
                                            position: "sticky",
                                            top: 0,
                                            height: "1.5rem",
                                            zIndex: 100 + tableData?.length,
                                        }}
                                        rowSpan={head?.row || 1}
                                        colSpan={head?.col || 1}
                                    >
                                        {head?.title}
                                    </th>
                                ))}
                            </tr>
                            <tr>
                                {[
                                    "DE No.",
                                    "Sub Delay Event",
                                    "Relevance",
                                    "Reference Letter",
                                    "Letter Contrasts (Party Communication)",
                                ]?.map((body) => (
                                    <th
                                        style={{
                                            border: "1px solid #D9D9D9",
                                            padding: "0.3rem 0.6rem",
                                            fontSize: "0.8rem",
                                            minWidth: "3rem",
                                            color: "#007BFF",
                                            background: "#E3F4FF",
                                            position: "sticky",
                                            top: "1.5rem",
                                            height: "1.5rem",
                                            zIndex: 100 + tableData?.length,
                                        }}
                                    >
                                        {body}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody ref={pageRef}>
                            {loader ? (
                                Array(7)
                                    ?.fill(0)
                                    ?.map((_) => (
                                        <tr>
                                            {Array(19)
                                                ?.fill(0)
                                                ?.map((_) => (
                                                    <TableCell
                                                        size="small"
                                                        sx={{ border: "1px solid #D9D9D9", fontSize: "0.8rem", p: 0 }}
                                                    >
                                                        <Skeleton variant="rectangular" sx={{ height: "2rem" }} />
                                                    </TableCell>
                                                ))}
                                        </tr>
                                    ))
                            ) : (
                                <Tables tableData={tableData} expand={expand} tableRef={tableRef} />
                            )}
                        </tbody>
                    </Table>
                </Stack>
                <Stack alignItems={"center"} justifyContent={"center"}>
                    <Pagination
                        count={pageRef?.current?.total || 1}
                        page={pageRef?.current?.page || 1}
                        color="secondary"
                        onChange={(_, value) => {
                            if (!loader && (pageRef?.current?.page || 1) !== value) {
                                pageRef.current.page = value;
                                sourceRef.current = source.source();
                                fetchData(sourceRef?.current?.token);
                            }
                        }}
                    />
                </Stack>
            </Paper>
        </Stack>
    );
};
const delay_event = [
    "DE-01",
    "DE-1A",
    "DE-02",
    "DE-03",
    "DE-04",
    "DE-05",
    "DE-06",
    "DE-07",
    "DE-08",
    "DE-09",
    "DE-10",
    "DE-11",
    "DE-12",
    "DE-13",
    "DE-14",
    "DE-15",
    "DE-16",
    "DE-17",
    "DE-18",
    "DE-19",
    "DE-20",
    "DE-21",
    "DE-22",
    "DE-22A",
    "DE-23",
    "DE-24",
    "DE-24A",
    "DE-24B",
    "DE-25",
    "DE-26",
    "DE-27",
    "DE-28",
    "DE-29",
    "DE-30",
    "DE-31",
    "DE-32",
    "DE-33",
    "DE-34",
    "DE-35",
    "DE-36",
    "DE-37",
    "DE-38",
    "DE-39",
    "DE-40",
    "DE-41",
    "DE-42",
    "DE-43",
    "DE-44",
    "DE-45",
    "DE-46",
    "DE-47",
    "DE-48",
    "DE-49",
    "DE-50",
    "DE-51",
    "DE-52",
    "DE-53",
    "DE-54",
    "DE-55",
    "DE-56",
    "DE-57",
    "DE-58",
    "DE-59",
    "DE-60",
    "DE-61",
    "DE-62",
    "DE-63",
];

export default Chronology;
