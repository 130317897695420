import { Check, Close } from "@mui/icons-material";
import { Stack, Typography, LinearProgress, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IconExclamationMark } from "@tabler/icons-react";
import ProgressSocket from "../../utils/ProgressSocket";

const Progress = ({ item, setFiles, response }: { item: any; setFiles: any; response: any }) => {
    const [value, setValue] = useState<number>(0);
    const [msg, socketHooks, msgRef] = ProgressSocket();
    const [status, setStatus] = useState<string>("init");
    useEffect(() => {
        const name = item?.name?.replace(".pdf", "");
        if (response[name]) {
            if (response[name] === "Pdf already exists, added to project!") {
                setValue(100);
            } else {
                socketHooks?.sendJsonMessage({
                    upload_status: { file_id: response[name] },
                });
            }
        }
    }, [response]);

    useEffect(() => {
        if (msgRef?.json?.file_id === response[item?.name?.replace(".pdf", "")]) {
            if (typeof msgRef?.json?.progress === "number") {
                if (msgRef?.json?.progress < 100) {
                    setTimeout(() => {
                        socketHooks?.sendJsonMessage({
                            upload_status: { file_id: msgRef?.json?.file_id },
                        });
                    }, 3000);
                }
                setValue(msgRef?.json?.progress);
            }
        }
    }, [msgRef?.json]);

    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={1}
            py={0.25}
            sx={{ "&:hover": { boxShadow: "0px 4px 7px 0px rgba(0,0,0,0.1)" } }}>
            <Typography noWrap sx={{ width: "18rem" }}>
                {item?.name}
            </Typography>
            <Stack flex={1}>
                <LinearProgress variant="determinate" value={value} sx={{ borderRadius: "19px" }} />
            </Stack>
            <IconButton onClick={() => setFiles((prev: any[]) => prev?.filter((file) => file?.name !== item?.name))}>
                <Close />
            </IconButton>
        </Stack>
    );
};

export default Progress;
