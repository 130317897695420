import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Table, TableHead, TableRow, TableCell, TableBody, Skeleton, Box, Button, IconButton, Typography } from "@mui/material";
import { useRef, useContext, useState, useEffect } from "react";
import { Context } from "../../../../context/setting";
import { Api } from "../../../../apis";
import Expand from "../../../../components/Common/svgLogs/Expand";
import Collapse from "../../../../components/Common/svgLogs/Collapse";
import moment from "moment";


const Chronology = () => {
    const tableRef = useRef<any>();
    const { contextStore, setContext } = useContext<any>(Context);
    const [expand, setExpand] = useState<boolean>(true);
    const [tableData, setTableData] = useState<any>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [filters] = useState<any>({
        package: "",
        party: "",
        delayEvent: "",
        sub_delayEvent: [],
        load: false,
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoader(true);
        const filterPayload = {
            table_name: "chronology",
            filter_dict: {
                ...(filters?.party !== "" ? { Party: filters?.party } : {}),
                ...(filters?.delayEvent !== "" ? { "Delay Event": filters?.delayEvent } : {}),
                ...(filters?.responseTime !== "" ? { "Response Time": filters?.responseTime } : {}),
                ...(filters?.sub_delay !== "" ? { "Sub Delay Event": filters?.sub_delay } : {}),
            },
        };
        Api?.getClaimsExistingDelayEvent({ user_id: contextStore?.profile?.userId, ...filterPayload })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    setTableData(JSON?.parse(res?.data?.chronology)?.slice(0, 10));
                    setContext({ ...contextStore, claimsNew: { ...contextStore?.claimsNew, parties: res?.data?.parties } });
                } else {
                    setTableData([]);
                }
                setLoader(false);
            })
            ?.catch((err) => setLoader(false));
    };

    return (
        <table ref={tableRef} style={{ borderCollapse: "collapse" }}>
            <thead style={{ position: "relative" }}>
                <tr>
                    <th
                        style={{
                            border: "1px solid #D9D9D9",
                            padding: "0.3rem 0.6rem",
                            fontSize: "0.8rem",
                            minWidth: "3rem",
                            color: "#007BFF",
                            background: "#E3F4FF",
                            cursor: "pointer",
                            position: "sticky",
                            top: 0,
                            height: "1.5rem",
                            zIndex: 100 + tableData?.length,
                            whiteSpace: "nowrap",
                        }}
                        rowSpan={2}
                        onClick={() => setExpand((prev: boolean) => !prev)}>
                        {expand ? <Expand /> : <Collapse />}
                    </th>
                    {[
                        { title: "Sn.", row: 2 },
                        { title: "Delay Event", col: 2 },
                        { title: "Party", row: 2 },
                        { title: "Department", row: 2 },
                        { title: "Previous Reference Letter", row: 2 },
                        { title: "Letter Reference No.", row: 2 },
                        { title: "Date", row: 2 },
                        { title: "Response Letter(s)", row: 2 },
                        { title: "Response Letter's Date", row: 2 },
                        { title: "Response Time", row: 2 },
                        { title: "Referred In Claims", row: 2 },
                        { title: "Missing in Claims", row: 2 },
                        { title: "Letter Type", row: 2 },
                        { title: "Gist", row: 2 },
                        { title: "Contradictions wrt the (Contract / FIDIC (Yellow / Pink Book)", col: 1 },
                        { title: "Contradictions wrt the Party's Letter", col: 2 },
                    ]?.map((head) => (
                        <th
                            style={{
                                border: "1px solid #D9D9D9",
                                padding: "0.3rem 0.6rem",
                                fontSize: "0.8rem",
                                minWidth: "3rem",
                                color: "#007BFF",
                                background: "#E3F4FF",
                                position: "sticky",
                                top: 0,
                                height: "1.5rem",
                                zIndex: 100 + tableData?.length,
                                whiteSpace: "nowrap",
                            }}
                            rowSpan={head?.row || 1}
                            colSpan={head?.col || 1}>
                            {head?.title}
                        </th>
                    ))}
                </tr>
                <tr>
                    {["DE No.", "Sub Delay Event", "Relevance", "Reference Letter", "Letter Contrasts (Party Communication)"]?.map((body) => (
                        <th
                            style={{
                                border: "1px solid #D9D9D9",
                                padding: "0.3rem 0.6rem",
                                fontSize: "0.8rem",
                                minWidth: "3rem",
                                color: "#007BFF",
                                background: "#E3F4FF",
                                position: "sticky",
                                top: "1.5rem",
                                height: "1.5rem",
                                zIndex: 100 + tableData?.length,
                                whiteSpace: "nowrap",
                            }}>
                            {body}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody style={{ position: "relative" }}>
                {loader
                    ? Array(7)
                          ?.fill(0)
                          ?.map((_) => (
                              <TableRow>
                                  {Array(19)
                                      ?.fill(0)
                                      ?.map((_) => (
                                          <TableCell size="small" sx={{ border: "1px solid #D9D9D9", fontSize: "0.8rem", p: 0 }}>
                                              <Skeleton variant="rectangular" sx={{ height: "2rem" }} />
                                          </TableCell>
                                      ))}
                              </TableRow>
                          ))
                    : tableData?.map((row: any, index: number) => <Body index={index} row={row} expand={expand} tableRef={tableRef} />)}
            </tbody>
        </table>
    );
};

const Body = ({ index, row, expand, tableRef }: { index: number; row: any; expand: boolean; tableRef: any }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [collapse, setCollapse] = useState<boolean>(true);
    useEffect(() => {
        setCollapse(expand);
    }, [expand]);

    const download = (url: string) => {
        Api?.downloadClaimFile({ blob_url: url })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    const a = document?.createElement("a");
                    a.hidden = true;
                    a.download = `Chronology ${row["Delay Event"]} ${row["Party"]} ${row["Sub Delay Event"]} ${
                        moment(row["Date"]?.toString())?.isValid() ? moment(row["Date"]?.toString())?.format("YYYY-MM-DD") : row["Date"]?.toString()
                    }`;
                    a.href = res?.data?.sas_url;
                    Promise?.resolve()
                        ?.then(() => {
                            tableRef.current.appendChild(a);
                            return true;
                        })
                        ?.then(() => {
                            a.click();
                            tableRef.current.removeChild(a);
                        })
                        ?.catch((err) => console.log(err));
                } else {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Failed To Download Document",
                        },
                    });
                }
            })
            ?.catch((err) => {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Failed To Download Document",
                    },
                });
            });
    };

    return collapse ? (
        <TableRow sx={{ "& p": { fontSize: "0.8rem" } }}>
            <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
                    {collapse ? <ArrowDropUp /> : <ArrowDropDown />}
                </IconButton>
            </TableCell>
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                {index + 1}
            </TableCell>
            {["Delay Event", "Sub Delay Event", "Party", "Department", "Previous Reference Letter"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                        {row[item]}
                    </Typography>
                </TableCell>
            ))}

            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                    {row["Letter Reference No."]}
                </Typography>
            </TableCell>
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                    {moment(row["Date"]?.toString())?.isValid() ? moment(row["Date"]?.toString())?.format("YYYY-MM-DD") : row["Date"]?.toString()}
                </Typography>
            </TableCell>
            {["Response Letter", "Response Letter's Date", "Response Time"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                        {row[item]?.toString()}
                    </Typography>
                </TableCell>
            ))}
            {["Referred"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                        {row[item]}
                    </Typography>
                </TableCell>
            ))}

            {["Missing in Claims"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                        {row[item]?.toString()}
                    </Typography>
                </TableCell>
            ))}
            {["Letter Type"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                        {row[item]?.toString()}
                    </Typography>
                </TableCell>
            ))}

            {/* <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
        {row["Reason for letter"]?.toString()}
    </TableCell> */}
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                    {row["Gist"]}
                </Typography>
            </TableCell>

            {["Relevancies"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    <Typography noWrap sx={{ width: "25rem", fontSize: "0.9rem" }}>
                        {row[item] || "NA"}
                    </Typography>
                </TableCell>
            ))}
            {/* {["Contract-Contrasts", "Clauses/Sub-Clauses in letter", "Relevancies"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                        {row[item]}
                    </Typography>
                </TableCell>
            ))} */}
            {["Contrasting Letter Number", "Letter Contrasts"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                        {(row[item] || "NA")?.toString()}
                    </Typography>
                </TableCell>
            ))}
        </TableRow>
    ) : (
        <TableRow sx={{ "& p": { fontSize: "0.8rem" } }}>
            <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
                    {collapse ? <ArrowDropUp /> : <ArrowDropDown />}
                </IconButton>
            </TableCell>
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                {index + 1}
            </TableCell>
            {["Delay Event", "Sub Delay Event", "Party", "Department", "Previous Reference Letter"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    {row[item]
                        ?.toString()
                        ?.split("\n")
                        ?.map((subItem: string) => (
                            <>
                                {subItem}
                                <br />
                            </>
                        ))}
                </TableCell>
            ))}

            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                {/* {row["Letter Reference No."]
    ?.toString()
    ?.split("(")
    ?.map((str: string) =>
        str?.startsWith("http") ? (
            <>
                {"("}
                <a href={str?.replace(")", "")} target="_blank">
                    {str?.replace(")", "")}
                </a>
                {")"}
            </>
        ) : (
            str
        )
    )} */}
                {row["Letter Reference No."]?.toString()?.includes("http") ? (
                    <>
                        {"["}
                        <Button sx={{ minWidth: "32px" }} onClick={() => download(row["Letter Reference No."]?.split("(")[1]?.replace(")", ""))}>
                            {row["Letter Reference No."]?.split("[")[1]?.split("(")[0]?.replace("]", "")}
                        </Button>
                        {"]"}
                    </>
                ) : (
                    row["Letter Reference No."]
                )}
            </TableCell>
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                {moment(row["Date"]?.toString())?.isValid() ? moment(row["Date"]?.toString())?.format("YYYY-MM-DD") : row["Date"]?.toString()}
            </TableCell>
            {["Response Letter", "Response Letter's Date", "Response Time"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    {row[item]?.toString()}
                </TableCell>
            ))}
            {["Referred"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    {row[item]
                        ?.toString()
                        ?.split("\n")
                        ?.map((subItem: string) => (
                            <>
                                {subItem}
                                <br />
                            </>
                        ))}
                </TableCell>
            ))}
            {["Missing in Claims"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    {row[item]?.toString()}
                </TableCell>
            ))}
            {["Letter Type"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    {row[item]?.toString()}
                </TableCell>
            ))}

            {/* <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
        {row["Reason for letter"]?.toString()}
    </TableCell> */}
            <TableCell
                sx={{ border: "1px solid #D9D9D9", p: 0.5, px: 2.4 }}
                size="small"
                // dangerouslySetInnerHTML={{
                //     __html: row["Gist"]
                //         ?.toString()
                //         ?.replace(row["Gist Contrasts"]?.toString(), `<span style="background:yellow; color:#000;">${row["Gist Contrasts"]}</span>`),
                // }}
            >
                <ul>
                    {row["Gist"]
                        ?.toString()
                        ?.split(". - ")
                        ?.filter((item: string) => item?.trim() !== "")
                        ?.map((item: string) => (
                            <li style={{ paddingTop: "8px" }}>
                                {item?.includes(row["Gist Contrasts"]) ? (
                                    item?.split(row["Gist Contrasts"])?.map((subItem: string, index: number) =>
                                        index === 0 ? (
                                            <>
                                                {subItem?.startsWith("-") ? subItem?.replace("-", "") : subItem}{" "}
                                                <span style={{ background: "yellow", color: "#000" }}>{row["Gist Contrasts"]}</span>
                                            </>
                                        ) : (
                                            subItem
                                        )
                                    )
                                ) : (
                                    <>{item?.startsWith("-") ? item?.replace("-", "") : item}</>
                                )}
                            </li>
                        ))}
                </ul>
            </TableCell>

            {/* {["Contract-Contrasts", "Clauses/Sub-Clauses in letter"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    {row[item]
                        ?.toString()
                        ?.split("\n")
                        ?.map((subItem: string) => (
                            <>
                                {subItem}
                                <br />
                            </>
                        ))}
                </TableCell>
            ))} */}
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                {/* {row["Relevancies"]?.toString()} */}
                <Relevencies value={`${(row["Relevancies"] || "NA")?.replaceAll("'", '"')}`} />
            </TableCell>
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                {/* {row["Relevancies"]?.toString()} */}
                <Relevencies value={`${(row["Contrasting Letter Number"] || "NA")?.replaceAll("'", '"')}`} />
            </TableCell>
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                {/* {row["Relevancies"]?.toString()} */}
                <Relevencies value={`${(row["Letter Contrasts"] || "NA")?.replaceAll("'", '"')}`} />
            </TableCell>
        </TableRow>
    );
};

const Relevencies = ({ value }: { value: any }) => {
    const [relevencie, setRelevencie] = useState<any>("");
    useEffect(() => {
        try {
            const json = JSON?.parse(value);
            setRelevencie(json);
        } catch (error) {
            setRelevencie(value);
        }
    }, [value]);

    if (Array?.isArray(relevencie)) {
        return (
            <Box sx={{ m: 1 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {["Document", "Clause Statement"]?.map((head) => (
                                <TableCell sx={{ background: "#c9d9ff", border: "1px solid #D9D9D9" }}>{head}</TableCell>
                            ))}
                            <TableCell sx={{ background: "#c9d9ff", border: "1px solid #D9D9D9", fontWeight: 600, color: "red", minWidth: "8rem" }}>
                                Contrasts
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {relevencie?.map((row: any) => (
                            <TableRow>
                                <TableCell size="small" sx={{ border: "1px solid #D9D9D9", minWidth: "10rem" }}>
                                    {row["Document"]}
                                </TableCell>
                                <TableCell size="small" sx={{ border: "1px solid #D9D9D9", minWidth: "10rem" }}>
                                    {row["Clause"]}
                                </TableCell>
                                <TableCell size="small" sx={{ border: "1px solid #D9D9D9", minWidth: "10rem" }}>
                                    {row["Contrasts"]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    }
    return <div>{relevencie}</div>;
};

export default Chronology;
