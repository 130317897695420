import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Paper,
    Table,
    TableRow,
    TableCell,
    Skeleton,
    CircularProgress,
    IconButton,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import DownloadExcel from "../Common/DownloadExcel";
import { Download } from "@mui/icons-material";
import ExcelJS from "exceljs";
import SaveAs from "file-saver";
import { Context } from "../../../../context/setting";
import { Api, source } from "../../../../apis";

const Clauses = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [filters, setFilters] = useState<any>({
        package: "",
        eot: "",
        delayEvent: "",
    });
    const [loader, setLoader] = useState<boolean>(false);
    const [tableData, setTableData] = useState<any[]>([]);
    const sourceRef = useRef(source?.source());

    useEffect(() => {
        sourceRef.current = source.source();
        fetchData(sourceRef?.current?.token);
        return () => sourceRef?.current?.cancel();
    }, []);

    const fetchData = (token?: any) => {
        setLoader(true);
        const filterPayload = {
            table_name: "clauses_result",
            filter_dict: {},
        };
        Api?.getClaimsExistingDelayEvent({ user_id: contextStore?.profile?.userId, ...filterPayload }, token)
            ?.then((res: any) => {
                if (res?.status === 200) {
                    setTableData(JSON?.parse(res?.data?.clauses_result));
                } else {
                    setTableData([]);
                }
                setLoader(false);
            })
            ?.catch((err) => setLoader(false));
    };

    const downloadExcel = ({ name }: { name: string }) => {
        if (tableData?.length > 0) {
            const workbook = new ExcelJS.Workbook();
            workbook.creator = "Claims-AI";
            workbook.created = new Date();
            let worksheet = workbook.addWorksheet(`${name} Report`);
            let headers: string[] = [];
            let lastHeadRow = 1;
            headers = ["Sr No.", ...Object?.keys(tableData[0])];
            worksheet.columns = headers?.map((key) => ({
                header: key,
                key,
                width: 10,
            }));
            ["A", "B", "C", "D"]?.forEach((row: string) => {
                worksheet.getCell(`${row}${lastHeadRow}`).fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "c9d9ff" },
                };
                worksheet.getCell(`${row}${lastHeadRow}`).font = {
                    color: { argb: "00000000" },
                    bold: true,
                };
                worksheet.getCell(`${row}${lastHeadRow}`).border = {
                    top: { style: "thin", color: { argb: "8F8F8F8F" } },
                    left: { style: "thin", color: { argb: "8F8F8F8F" } },
                    bottom: { style: "thin", color: { argb: "8F8F8F8F" } },
                    right: { style: "thin", color: { argb: "8F8F8F8F" } },
                };
                worksheet.getColumn(`${row}`).width = 35;
            });
            tableData?.forEach((item: any, index: number) => {
                worksheet.addRow([`${index + 1}`, ...[...Object?.keys(item)]?.map((key) => item[key]?.toString())]);
                lastHeadRow += 1;
            });
            worksheet.eachRow((row) =>
                row.eachCell(
                    (cell) =>
                        (cell.alignment = {
                            horizontal: "center",
                        })
                )
            );
            worksheet.views = [
                {
                    state: "frozen",
                    xSplit: 0,
                    ySplit: 0,
                    activeCell: "A1",
                    showGridLines: true,
                },
            ];
            workbook.xlsx.writeBuffer().then((data) => {
                let blob = new Blob([data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                SaveAs(blob, `SoftsensorX Claims ${name} Report`);
            });
        }
    };

    return (
        <Stack gap={2} flex={1}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <FormControl size="small">
                        <InputLabel sx={{ top: "-3px", fontSize: "0.9rem" }}>Package</InputLabel>
                        <Select
                            value={filters?.package}
                            onChange={(e: { target: { value: string } }) =>
                                setFilters((prev: any) => ({ ...prev, package: e?.target?.value }))
                            }
                            label={"Package"}
                            size="small"
                            sx={{
                                minWidth: "8rem",
                                fontSize: "0.9rem",
                                fontFamily: "Gilroy-Medium",
                                height: "2rem",
                                background: "#fff",
                            }}
                        >
                            <MenuItem value={""} sx={{ fontStyle: "italic" }}>
                                N/A
                            </MenuItem>
                            <MenuItem value={"C4"}>C4</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl size="small">
                        <InputLabel sx={{ top: "-3px", fontSize: "0.9rem" }}>EOT</InputLabel>
                        <Select
                            value={filters?.eot}
                            onChange={(e: { target: { value: string } }) =>
                                setFilters((prev: any) => ({ ...prev, eot: e?.target?.value }))
                            }
                            size="small"
                            label="EOT"
                            sx={{
                                minWidth: "7rem",
                                fontSize: "0.9rem",
                                fontFamily: "Gilroy-Medium",
                                height: "2rem",
                                background: "#fff",
                            }}
                        >
                            <MenuItem value={""} sx={{ fontStyle: "italic" }}>
                                N/A
                            </MenuItem>
                            <MenuItem value={"EOT-3"}>EOT-3</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <Button
                        sx={{
                            px: 3.5,
                            py: 1,
                            background: "#007BFF",
                            color: "#fff",
                            boxShadow:
                                "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
                        }}
                        onClick={() => {
                            sourceRef.current = source.source();
                            fetchData(sourceRef?.current?.token);
                        }}
                    >
                        Filter
                    </Button>
                    {/* <DownloadExcel name="clauses" disable={loader} /> */}
                    <IconButton
                        disabled={loader}
                        sx={{ width: "2rem", height: "2rem", borderRadius: "8px", border: "1px solid #d9d9d9" }}
                        onClick={() => downloadExcel({ name: "Clauses" })}
                    >
                        {loader ? (
                            <CircularProgress style={{ width: "20px", height: "20px" }} />
                        ) : (
                            <Download sx={{ fontSize: "1.2rem", color: "#007BFF" }} />
                        )}
                    </IconButton>
                </Stack>
            </Stack>
            <Paper
                elevation={3}
                sx={{
                    p: 2,
                    borderRadius: "8px",
                    gap: 2,
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Stack sx={{ pr: 1, flex: "1 0 10vh", overflowY: "auto" }}>
                    <Table stickyHeader size="small">
                        <thead>
                            <tr>
                                <th
                                    style={{
                                        border: "1px solid #D9D9D9",
                                        padding: "0.3rem 0.6rem",
                                        fontSize: "0.8rem",
                                        minWidth: "3rem",
                                        color: "#007BFF",
                                        background: "#E3F4FF",
                                        cursor: "pointer",
                                        position: "sticky",
                                        top: 0,
                                        height: "1.5rem",
                                        zIndex: 100 + tableData?.length,
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    Sn No.
                                </th>
                                {["Clause Number", "Details", "Mention in Contractor's Claim"]?.map((head: string) => (
                                    <th
                                        style={{
                                            border: "1px solid #D9D9D9",
                                            padding: "0.3rem 0.6rem",
                                            fontSize: "0.8rem",
                                            minWidth: "3rem",
                                            color: "#007BFF",
                                            background: "#E3F4FF",
                                            cursor: "pointer",
                                            position: "sticky",
                                            top: 0,
                                            height: "1.5rem",
                                            zIndex: 100 + tableData?.length,
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {head}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {loader
                                ? Array(7)
                                      ?.fill(0)
                                      ?.map((_) => (
                                          <TableRow>
                                              {Array(4)
                                                  ?.fill(0)
                                                  ?.map((_) => (
                                                      <TableCell
                                                          size="small"
                                                          sx={{ border: "1px solid #D9D9D9", fontSize: "0.8rem", p: 0 }}
                                                      >
                                                          <Skeleton variant="rectangular" sx={{ height: "2rem" }} />
                                                      </TableCell>
                                                  ))}
                                          </TableRow>
                                      ))
                                : tableData?.map((row: any, index: number) => (
                                      <tr>
                                          <td
                                              style={{
                                                  border: "1px solid #D9D9D9",
                                                  padding: "0.3rem 0.6rem",
                                                  fontSize: "0.8rem",
                                                  position: "sticky",
                                                  top: "1.5rem",
                                                  background: "#fff",
                                                  zIndex: 100 + index,
                                              }}
                                          >
                                              {index + 1}
                                          </td>
                                          {Object?.keys(row)?.map((item: string) => (
                                              <td
                                                  style={{
                                                      border: "1px solid #D9D9D9",
                                                      padding: "0.3rem 0.6rem",
                                                      fontSize: "0.8rem",
                                                      position: "sticky",
                                                      top: "1.5rem",
                                                      background: "#fff",
                                                      zIndex: 100 + index,
                                                  }}
                                              >
                                                  {row[item]?.toString()}
                                              </td>
                                          ))}
                                      </tr>
                                  ))}
                        </tbody>
                    </Table>
                </Stack>
            </Paper>
        </Stack>
    );
};

const delay_event = [
    "DE-01",
    "DE-1A",
    "DE-02",
    "DE-03",
    "DE-04",
    "DE-05",
    "DE-06",
    "DE-07",
    "DE-08",
    "DE-09",
    "DE-10",
    "DE-11",
    "DE-12",
    "DE-13",
    "DE-14",
    "DE-15",
    "DE-16",
    "DE-17",
    "DE-18",
    "DE-19",
    "DE-20",
    "DE-21",
    "DE-22",
    "DE-22A",
    "DE-23",
    "DE-24",
    "DE-24A",
    "DE-24B",
    "DE-25",
    "DE-26",
    "DE-27",
    "DE-28",
    "DE-29",
    "DE-30",
    "DE-31",
    "DE-32",
    "DE-33",
    "DE-34",
    "DE-35",
    "DE-36",
    "DE-37",
    "DE-38",
    "DE-39",
    "DE-40",
    "DE-41",
    "DE-42",
    "DE-43",
    "DE-44",
    "DE-45",
    "DE-46",
    "DE-47",
    "DE-48",
    "DE-49",
    "DE-50",
    "DE-51",
    "DE-52",
    "DE-53",
    "DE-54",
    "DE-55",
    "DE-56",
    "DE-57",
    "DE-58",
    "DE-59",
    "DE-60",
    "DE-61",
    "DE-62",
    "DE-63",
];

export default Clauses;
