import { Stack } from "@mui/material";
import React, { useState } from "react";
import NewProject from "./New_Project/NewProject";
import Projects from "./Projects/Projects";
import Card from "./Card";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const Home = () => {
    const [date, setDate] = useState<any>();
    return (
        <Stack flex={1} p={2} gap={2}>
            <Stack direction={"row"} gap={2}>
                <Card item={"Total Projects"} />
                <Card item={"Delay Events"} />
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="Date"
                        sx={{
                            "& .MuiInputBase-input": { fontSize: "0.8rem", py: 0.86 },
                            "& .MuiInputLabel-root": {
                                transform: date ? "translate(14px, -9px) scale(0.75)" : "translate(14px, 4px) scale(1)",
                                transition: "all 0.2s ease-in-out",
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                transform: "translate(14px, -9px) scale(0.75)",
                            },
                            width: "8rem",
                        }}
                        onChange={(newValue: any) => setDate(newValue)}
                    />
                </LocalizationProvider>
                <NewProject />
            </Stack>
            <Projects date={date} />
        </Stack>
    );
};

export default Home;
