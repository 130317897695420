import { Stack, Typography, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/setting";
import { Api } from "../../../apis";


const Card = ({ item }: { item: string }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [counts, setCounts] = useState<any>({ totalProject: 0, delayEvent: 0 });
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        fetchCounts();
    }, []);

    const fetchCounts = () => {
        setLoader(true);
        if (item === "Total Projects") {
            Api?.getProjectCounts(contextStore?.profile?.userId)?.then((res: any) => {
                if (res?.status === 200) {
                    setCounts((prev: any) => ({ ...prev, totalProject: res?.data?.projects_count }));
                }
                setLoader(false);
            });
        }
        if (item === "Delay Events") {
            Api?.getEventCounts()?.then((res: any) => {
                if (res?.status === 200) {
                    setCounts((prev: any) => ({ ...prev, delayEvent: res?.data?.delay_event_count }));
                }
                setLoader(false);
            });
        }
    };

    return (
        <Stack
            flex={1}
            sx={{
                background: item === "Total Projects" ? "#F6F4FE" : "#FEF4FE",
                py: 1,
                px: 2,
                borderRadius: "10px",
                "& *": {
                    color: item === "Total Projects" ? "#1B75BC" : "#B451B4",
                },
                gap: 1,
                position: "relative",
            }}>
            <Typography variant="subtitle2">{item}</Typography>
            <Typography variant="h4" fontWeight={600}>
                {loader ? (
                    <CircularProgress style={{ width: "20px", height: "20px" }} />
                ) : item === "Total Projects" ? (
                    counts?.totalProject
                ) : (
                    counts?.delayEvent
                )}
            </Typography>
        </Stack>
    );
};

export default Card;
