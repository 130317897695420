import React from "react";

const Upload = () => {
    return (
        <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.1938 5.55868L15.5859 0.855494C15.2979 0.56159 14.9073 0.396484 14.5 0.396484C14.0927 0.396484 13.7021 0.56159 13.4141 0.855494L8.80616 5.55868C8.52638 5.85436 8.37156 6.25037 8.37506 6.66143C8.37856 7.07248 8.54009 7.46568 8.82487 7.75635C9.10966 8.04702 9.4949 8.2119 9.89762 8.21547C10.3003 8.21905 10.6883 8.06103 10.978 7.77545L12.964 5.74838V23.9121C12.964 24.3279 13.1259 24.7266 13.4139 25.0206C13.702 25.3147 14.0926 25.4798 14.5 25.4798C14.9074 25.4798 15.298 25.3147 15.5861 25.0206C15.8741 24.7266 16.036 24.3279 16.036 23.9121V5.74838L18.022 7.77545C18.3117 8.06103 18.6997 8.21905 19.1024 8.21547C19.5051 8.2119 19.8903 8.04702 20.1751 7.75635C20.4599 7.46568 20.6214 7.07248 20.6249 6.66143C20.6284 6.25037 20.4736 5.85436 20.1938 5.55868Z"
                fill="#007BFF"
            />
            <path
                d="M23.8333 11.1875H16.0556V23.7516C16.0556 24.1681 15.8917 24.5676 15.5999 24.8621C15.3082 25.1567 14.9126 25.3221 14.5 25.3221C14.0874 25.3221 13.6918 25.1567 13.4001 24.8621C13.1083 24.5676 12.9444 24.1681 12.9444 23.7516V11.1875H5.16667C3.92937 11.1887 2.7431 11.6855 1.8682 12.5689C0.993298 13.4522 0.501235 14.6498 0.5 15.899V26.8926C0.501235 28.1418 0.993298 29.3395 1.8682 30.2228C2.7431 31.1061 3.92937 31.6029 5.16667 31.6042H23.8333C25.0706 31.6029 26.2569 31.1061 27.1318 30.2228C28.0067 29.3395 28.4988 28.1418 28.5 26.8926V15.899C28.4988 14.6498 28.0067 13.4522 27.1318 12.5689C26.2569 11.6855 25.0706 11.1887 23.8333 11.1875Z"
                fill="#E3F4FF"
            />
        </svg>
    );
};

export default Upload;
