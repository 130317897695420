import { ArrowBackIos } from "@mui/icons-material";
import { Button, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { startTransition, useContext, useEffect, useState } from "react";

import Overview from "./Overview/Overview";
import Files from "./Files/Files";
import DelayEvents from "./DelayEvents/DelayEvents";
import Chronology from "./Chronology/Chronology";
import Clauses from "./Clauses/Clauses";
import { Context } from "../../../context/setting";

const Database = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [project, setProject] = useState<any>({});
    const [selectedTab, setSelectedTab] = useState("overview");
    useEffect(() => {
        if (Object?.keys(contextStore?.claimsNew?.selectedPorject)?.length > 0) {
            setProject(contextStore?.claimsNew?.selectedPorject);
        } else {
            setProject(contextStore?.claimsNew?.projects[0]);
        }
    }, [contextStore?.claimsNew?.selectedPorject]);

    return (
        <Stack flex={1}>
            <Stack direction={"row"} alignItems={"center"} gap={3} px={2} py={1}>
                <Button
                    sx={{ "& .MuiButton-startIcon>*:nth-of-type(1)": { fontSize: "0.8rem" } }}
                    startIcon={<ArrowBackIos />}
                    onClick={() =>
                        setContext({
                            ...contextStore,
                            claimsNew: { ...contextStore?.claimsNew, selectedHome: "Home" },
                        })
                    }
                >
                    Back
                </Button>
                <Typography fontWeight={"bold"}>{project?.name}</Typography>
            </Stack>
            <Tabs
                value={selectedTab}
                sx={{ "& *": { fontSize: "0.9rem" } }}
                onChange={(_, value: string) =>
                    startTransition(() => {
                        setSelectedTab(value);
                    })
                }
            >
                <Tab value="overview" label="Overview" wrapped />
                <Tab value="file" label="File" />
                <Tab value="delay_events" label="Delay Events" />
                <Tab value="chronology" label="Chronology" />
                <Tab value="clauses" label="Clauses" />
                {/* <Tab value="eot_comparison" label="EOT Comparison" /> */}
            </Tabs>
            <Stack flex={1} sx={{ background: "#EDEEF1" }} p={2}>
                <TabSwitch tab={selectedTab} setSelectedTab={setSelectedTab} />
            </Stack>
        </Stack>
    );
};

const TabSwitch = ({ tab, setSelectedTab }: { tab: string; setSelectedTab: any }) => {
    switch (tab) {
        case "overview":
            return <Overview setSelectedTab={setSelectedTab} />;
        case "file":
            return <Files />;
        case "delay_events":
            return <DelayEvents />;
        case "chronology":
            return <Chronology />;
        case "clauses":
            return <Clauses />;

        default:
            return <Overview setSelectedTab={setSelectedTab} />;
    }
};

export default Database;
