import React from "react";

const Edit = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 2.75H4.58333C4.0971 2.75 3.63079 2.94315 3.28697 3.28697C2.94315 3.63079 2.75 4.0971 2.75 4.58333V17.4167C2.75 17.9029 2.94315 18.3692 3.28697 18.713C3.63079 19.0568 4.0971 19.25 4.58333 19.25H17.4167C17.9029 19.25 18.3692 19.0568 18.713 18.713C19.0568 18.3692 19.25 17.9029 19.25 17.4167V11"
                stroke="#007BFF"
                stroke-width="1.83333"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M16.8424 2.40548C17.2071 2.04081 17.7017 1.83594 18.2174 1.83594C18.7332 1.83594 19.2278 2.04081 19.5924 2.40548C19.9571 2.77015 20.162 3.26476 20.162 3.78048C20.162 4.29621 19.9571 4.79081 19.5924 5.15548L10.9987 13.7492L7.33203 14.6659L8.2487 10.9992L16.8424 2.40548Z"
                stroke="#007BFF"
                stroke-width="1.83333"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default Edit;
