import React from "react";

const Redirect = () => {
    return (
        <svg width="23" height="20" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <rect y="0.859375" width="27.8521" height="23.9577" fill="url(#pattern0_11662_4512)" />
            <defs>
                <pattern id="pattern0_11662_4512" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image0_11662_4512" transform="matrix(0.00896018 0 0 0.0104167 0.0699113 0)" />
                </pattern>
                <image
                    id="image0_11662_4512"
                    width="96"
                    height="96"
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEiklEQVR4nO2dTY8bRRCGG8LHgcC/QIggAgihIC6ckFYI7Oqs9i9w5eOCwof2irgg+AEgDkCYS2zX60mcyyLxccpPCHAKCRcWskiQQGJUw64UYO0ZE09V2VOP1Kfdna5633Z3T7u7N6UgCIIgCIIgCIIgWDW2t7fvtI7BFcPN4SPIGHHmX5AxVSmEP5n4PPp4PHWZcX/8KAh7asL/q3DmXzttAksrNBL/ljJJXaTYKo4w8XVrAzjz79M0vSN1jcKJAVI6aYCjLmiaLLjw0oW7xzR+gYnfR8Y3TPzjslsky8yGMCx75TGPg7CJAeVGee+IRq+J4GpJEvZE7EPj6ZXHOPMAGbtrbwBn7nHm79dxtoEeTjDxzy4NkEEGGa+DcMOqlTHxdRl428gPhCeR8ZPLT0AlPuFjK+FxUAjX2jDgdlq+igEgbJuLnysDznlr+a0bAEKfM980Fz/j6qxB2LLlt2qAzHYMB9zpfpGZzRkQHrYSv8nvpTaQqWbDAC8z8SnO/Njkucl9yTlYVPweTqgbIC9ZTeb5nPnTVRD9dsSv/k7bAHnDbRDgJ6u0BoL/Kb6JAUz8QV23U2wVR1MHxDcxQNZ2aoI8lToivtUnYG7/P9wcHk8dEd/mE0C4Nq/CVeh+sMBL1jzxrbog3Qodi189LwywE796ZhhgJ3713DDATvzq2WGAnfjV88MAO/GrOsIAO/GresIAO/GrusIAO/Gr+sIAO/GFmuWM3dSVN2EsaW1n4XoJwzl1nVlGHe4NgJH4B5u/ZuzAuzpr195aGQBD8f+xDTJjUi1U/r1YOVn2RgGXBsCB+Lcie5Pa2iDmzoCzvbMPNd3zqSG+Gl4MAGHcOfG9GFBsFUfqvhhqU3yVrsazATvP7tzFmf/QFl8GVjn4IZuB98v51gZbzwYIyPhCU/zqyOth003Cnvwsdc2AUR49xcS/HRLDbhvdjpw3nml45sGy63NvwIEJyNiR7khOJFaHsk/yg6kF1JccVsGAA2RAbPuaADd5uwlEGTd5uwlEGTd5uwlEGTd5uwlEGTd5uwlEGTd5uwlEGTd5uwlEGTd5uwlEGTd5uwlEGTd5uwlEGTd5uwlEGTd5uwlEGTd5uwlEGTd5uwlEGTd5uwlEGTd5uwlEGTd5uwlEGTd5uwlEGTd5r8NB7UUpN8oH5uUs30cnLdblqoJFkEu45xpAfDlpwZm/rmkNb6Q1A4S3arqgL9WC4czv1gRzZZ26oUFvcH/tBVWEd9QCQh/P1A1IIJxepQubZiE5cObP6/Itc/l00kL23zDxDw1M+GyVPwkDafkNxEfGJfV/XYKMVxsEVnVHILxZbpZPrIIZxVZxVGKVPr/p/ddM/LJFoPdw5osNTVjn8p1c4ZksGPVHLzq5uHVqUgg3+CQ/nyzhzG+bC5Ftiovp9v4s4aMOtv4P3czyPFxfD71Wf1Pm/G7E/8+FroRv11j8i+Z9fsPZ0SuyNmItGJZXLslUU3JLq4IcokPGBgjvMfFX++8DtacaYd/K5ZTNFVnbka5G3nDj/0MGQRAEQRAEQRAEQWqBvwBmfJTm07rg2AAAAABJRU5ErkJggg=="
                />
            </defs>
        </svg>
    );
};

export default Redirect;
