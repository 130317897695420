import { Box, Button, Divider, ListItem, Paper, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../../context/setting";
import { Api } from "../../../../apis";

const AutoFilter = ({ setFilterScreeen }: { setFilterScreeen: any }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [loader, setLoader] = useState<boolean>(false);
    const [filterDocs, setFilterDocs] = useState<any[]>([]);

    useEffect(() => {
        handleFilter();
    }, []);

    const handleFilter = () => {
        if (contextStore?.claimsNew?.filterDocuments?.length > 0) {
            setLoader(true);
            const filterpdfs = contextStore?.claimsNew?.filterDocuments?.reduce(
                (filter: any, item: any) => {
                    if (item?.claim_status === "Claim") {
                        filter?.claim_pdfs?.push({
                            pdf_id: item?.pdf_id,
                            Date: item?.publish_date,
                            pdf_name: item?.pdf_name,
                        });
                    } else {
                        filter?.check_pdfs?.push({
                            pdf_id: item?.pdf_id,
                            Date: item?.publish_date,
                            pdf_name: item?.pdf_name,
                        });
                    }
                    return filter;
                },
                { claim_pdfs: [], check_pdfs: [] }
            );
            Api?.getClaimsExistingProjectsFilter(filterpdfs)
                ?.then((res: any) => {
                    if (res?.status === 200) {
                        setFilterDocs(res?.data);
                    }
                    setLoader(false);
                })
                ?.catch((err) => {
                    setFilterDocs([]);
                    setLoader(false);
                });
        }
    };

    return (
        <Paper
            elevation={3}
            sx={{ p: 2, borderRadius: "8px", gap: 2, display: "flex", flexDirection: "column", flex: 1 }}
        >
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography color={"#007BFF"}>Document List</Typography>
                <Button
                    sx={{
                        px: 3.5,
                        py: 1,
                        background: "#007BFF",
                        color: "#fff",
                        boxShadow:
                            "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
                    }}
                    variant="contained"
                    onClick={() => setFilterScreeen("init")}
                >
                    Back
                </Button>
            </Stack>
            <Stack sx={{ flex: "1 0 10vh", overflowY: "auto" }}>
                <Stack direction={"row"} sx={{ gap: 2, flexWrap: "wrap" }}>
                    {loader
                        ? Array(4)
                              ?.fill(0)
                              ?.map((_) => <Skeleton variant="rounded" width={"16rem"} height={"16rem"} />)
                        : filterDocs?.map((doc) => (
                              <Stack
                                  width={"30vw"}
                                  height={"20rem"}
                                  sx={{ border: "1px solid #D9D9D9", borderRadius: "10px", p: 1, gap: 1 }}
                              >
                                  <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                      <Stack flex={1}>
                                          <Typography variant="caption" sx={{ fontSize: "0.7rem", color: "#12924F" }}>
                                              Claim Documents
                                          </Typography>
                                          <Tooltip title={doc?.pdf?.pdf_name}>
                                              <Typography
                                                  variant="caption"
                                                  sx={{ fontSize: "0.8rem", width: "10rem" }}
                                                  noWrap
                                              >
                                                  {doc?.pdf?.pdf_name}
                                              </Typography>
                                          </Tooltip>
                                      </Stack>
                                      <Stack direction={"row"} gap={1} alignItems={"center"}>
                                          <Button
                                              sx={{
                                                  fontSize: "0.8rem",
                                                  height: "2rem",
                                                  borderColor: "#007BFF",
                                                  color: "#007BFF",
                                              }}
                                              variant="outlined"
                                              onClick={() => {
                                                  setContext({
                                                      ...contextStore,
                                                      claimsNew: { ...contextStore?.claimsNew, compare: doc },
                                                  });
                                                  setFilterScreeen("compare");
                                              }}
                                          >
                                              Compare
                                          </Button>
                                          <Button
                                              sx={{ fontSize: "0.8rem", height: "2rem", background: "#007BFF" }}
                                              variant="contained"
                                              onClick={() => {
                                                  setContext({
                                                      ...contextStore,
                                                      claimsNew: { ...contextStore?.claimsNew, compare: doc },
                                                  });
                                                  setFilterScreeen("chat");
                                              }}
                                          >
                                              Chat
                                          </Button>
                                      </Stack>
                                  </Stack>
                                  <Stack flex={1}>
                                      <Typography
                                          variant="caption"
                                          sx={{ fontSize: "0.8rem", color: "#d9d9d9" }}
                                          noWrap
                                      >
                                          Matching Documents
                                      </Typography>
                                      <Divider />
                                      <Stack sx={{ flex: "1 0 10vh", overflowY: "auto" }}>
                                          {doc?.top_match_docs?.map((subItem: any) => (
                                              <Stack>
                                                  <Stack direction={"row"} justifyContent={"space-between"} py={1.5}>
                                                      <Typography
                                                          sx={{ fontSize: "0.8rem", width: "12vw" }}
                                                          variant="caption"
                                                          noWrap
                                                      >
                                                          {subItem?.pdf_name}
                                                      </Typography>
                                                      <Typography sx={{ fontSize: "0.7rem" }} variant="caption">
                                                          {subItem?.Date}
                                                      </Typography>
                                                  </Stack>
                                                  <Divider />
                                              </Stack>
                                          ))}
                                      </Stack>
                                  </Stack>
                              </Stack>
                          ))}
                </Stack>
            </Stack>
        </Paper>
    );
};

export default AutoFilter;
