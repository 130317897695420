import { Download } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../../context/setting";


const DownloadExcel = ({ name, disable }: { name: string; disable: boolean }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [loader, setLoader] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
        setDisabled(disable);
    }, [disable]);

    const downloadExcel = async () => {
        setLoader(true);
        try {
            const response = await fetch("https://development-claims-ssx.softsensor.ai/claims/download_letters", {
                method: "GET",
                headers: {
                    "Content-Type": "application/octet-stream",
                },
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = `claims_${name}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            setContext({
                ...contextStore,
                snackState: {
                    open: true,
                    errorType: "error",
                    message: "Server issue! Please try after some time",
                },
            });
        }
    };
    return (
        <IconButton
            disabled={disabled}
            sx={{ width: "2rem", height: "2rem", borderRadius: "8px", border: "1px solid #d9d9d9" }}
            onClick={downloadExcel}>
            {loader ? <CircularProgress style={{ width: "20px", height: "20px" }} /> : <Download sx={{ fontSize: "1.2rem", color: "#007BFF" }} />}
        </IconButton>
    );
};

export default DownloadExcel;
