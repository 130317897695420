import { Stack, Table, TableHead, TableCell, Typography, TableBody, TableRow, Skeleton, Tooltip } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { ErrorOutline } from "@mui/icons-material";
import { Context } from "../../../../context/setting";
import moment from "moment";
import { Api } from "../../../../apis";
import DoneTick from "../../../../components/Common/svgLogs/DoneTick";

const Projects = ({ date }: { date: any }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [loader, setLoader] = useState<boolean>(false);
    const [projects, setProjects] = useState<any[]>([]);

    useEffect(() => {
        if (contextStore?.profile?.userId) {
            setLoader(true);
            if (date) {
                fetchProjects(moment(new Date(date))?.format("YYYY-MM-DD"));
            } else if (contextStore?.claimsNew?.projects?.length > 0) {
                setProjects(() => contextStore?.claimsNew?.projects || []);
                setLoader(false);
            } else {
                fetchProjects(moment(new Date(date))?.format("YYYY-MM-DD"));
            }
        }
    }, [date, contextStore?.profile?.userId]);

    const fetchProjects = useCallback(
        (day: string) => {
            Api?.getClaimsExistingProjects({
                user_id: contextStore?.profile?.userId,
                ...(date ? { created_at: day } : {}),
            })
                ?.then((res: any) => {
                    if (res?.status === 200) {
                        setProjects(() => res?.data?.projects);
                        setContext({
                            ...contextStore,
                            claimsNew: { ...contextStore?.claimsNew, projects: res?.data?.projects },
                        });
                        // if (res?.data?.projects?.find((item: any) => item?.project_process >= 1)) {
                        //     setTimeout(() => {
                        //         fetchProjects(moment(new Date(date))?.format("YYYY-MM-DD"));
                        //     }, 30000);
                        // }
                    }
                    setLoader(false);
                })
                ?.catch((err) => setLoader(false));
        },
        [date]
    );

    const fileProgress = (files: number) => {
        if (isNaN(Number(files)) || files === undefined) {
            return <ErrorOutline />;
        }
        if (files === 0) {
            return <DoneTick />;
        }
        return (
            <Tooltip title={`Ingesting ${files} Files`}>
                <Stack alignItems={"center"}>
                    <div
                        className="load-wave"
                        style={{
                            width: "0.9rem",
                            height: "0.9rem",
                            borderRadius: "50%",
                            border: "1px solid #007BFF",
                            position: "relative",
                            overflow: "hidden",
                        }}></div>
                </Stack>
            </Tooltip>
        );
    };

    return (
        <Stack sx={{ flex: "1 0 10vh", overflowY: "auto", overflowX: "hidden" }}>
            <Table sx={{ border: "1px solid #EEEEEE", borderTop: "none" }} stickyHeader>
                <TableHead>
                    <tr>
                        {["S. No.", "Project", "Date Created", "Date Modified", "Files", ""]?.map((thead) => (
                            <TableCell sx={{ py: "4px", textAlign: "center", background: "#F3F3F4", borderTop: "1px solid #EEEEEE" }}>
                                <Typography variant="subtitle1">{thead}</Typography>
                            </TableCell>
                        ))}
                    </tr>
                </TableHead>
                <TableBody>
                    {loader
                        ? Array(7)
                              ?.fill(0)
                              ?.map((_) => (
                                  <TableRow>
                                      {Array(5)
                                          ?.fill(0)
                                          ?.map((_) => (
                                              <TableCell size="small" sx={{ border: "1px solid #D9D9D9", fontSize: "0.8rem", p: 0 }}>
                                                  <Skeleton variant="rectangular" sx={{ height: "2rem" }} />
                                              </TableCell>
                                          ))}
                                  </TableRow>
                              ))
                        : projects?.map((body, index: number) => (
                              <tr className="tr_hover" style={{ textAlign: "center", borderBottom: "1px solid rgba(0, 0, 0, 0.04)" }}>
                                  {[
                                      index + 1,
                                      body["name"],
                                      moment(body["created_at"])?.format("DD MMMM YYYY"),
                                      moment(body["created_at"])?.format("DD MMMM YYYY"),
                                      body["pdfs_count"] || body["pdf_counts"],
                                      fileProgress(body?.project_process),
                                  ]?.map((item) => (
                                      <td
                                          style={{ padding: "4px 0px 4px 0px", cursor: "pointer" }}
                                          onClick={() =>
                                              setContext({
                                                  ...contextStore,
                                                  claimsNew: { ...contextStore?.claimsNew, selectedHome: "DataBase", selectedPorject: body },
                                              })
                                          }>
                                          <Typography variant="subtitle2">{item}</Typography>
                                      </td>
                                  ))}
                              </tr>
                          ))}
                </TableBody>
            </Table>
        </Stack>
    );
};

export default Projects;
