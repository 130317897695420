import { Skeleton, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../../context/setting";
import { Api } from "../../../../apis";


const Clauses = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [tableData, setTableData] = useState<any>([]);
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoader(true);
        Api?.getClaimsExistingDelayEvent({
            user_id: contextStore?.profile?.userId,
            table_name: "clauses_result",
            filter_dict: {
                max_rows: "3",
            },
        })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    setTableData(JSON?.parse(res?.data?.clauses_result));
                } else {
                    setTableData([]);
                }
                setLoader(false);
            })
            ?.catch((err) => setLoader(false));
    };

    return loader ? (
        <Stack gap={1}>
            {Array(3)
                ?.fill(0)
                ?.map((_) => (
                    <Skeleton variant="rounded" height={"2.5rem"} />
                ))}
        </Stack>
    ) : (
        <Stack gap={0.5}>
            {tableData?.map((card: any) => (
                <Stack sx={{ background: "#F9F9F9", px: 1, py: 0.3, borderRadius: "8px" }}>
                    <Typography variant="caption" fontWeight={"bold"}>
                        Clause No {card?.["Clause Number"]}
                    </Typography>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography variant="caption" noWrap>
                            {card?.Details}
                        </Typography>
                        {/* <Typography sx={{ color: "#007BFF", textDecoration: "underline", cursor: "pointer", fontSize: "0.9rem" }}>Open</Typography> */}
                    </Stack>
                </Stack>
            ))}
        </Stack>
    );
};

export default Clauses;
